import { View, Pressable, Text, Platform } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import * as Sentry from 'sentry-expo'

import { colors, IMAGES } from '../../Helpers/variables'
import { Input } from '../Forms/Input'
import useLayout from '../../Hooks/useLayout'
import AuthService from '../../Services/AuthService'
import useAuth from '../../Hooks/useAuth'
import useSound from '../../Hooks/useSound'
import { SettingMenu } from './SettingMenu'
import OfflineBar from './OfflineBar'
import api from '../../Helpers/api'
import { useEffect } from 'react'
import { ScheduleMenu } from './ScheduleMenu'

export default function Header({ navigation, title }) {
	const { isSidebarOpen, setOfflineOnlineStatus, isOffline } = useLayout()
	const { setIsLoggedIn, setIsAdminViewOpen, isAdmin } = useAuth()

	const handleAdminView = () => {
		setIsAdminViewOpen(true)
	}

	const { sound } = useSound()
	const handleSearch = async target => {
		navigation.navigate('SearchScreen', { searchInput: target.nativeEvent.text })
	}

	const handleHelp = async target => {
		navigation.navigate('HelpScreen')
	}

	const handleLogout = async () => {
		AuthService.logout()

		try {
			await sound.current.unloadAsync()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
		setIsLoggedIn(false)
	}

	const checkOnlineStatus = async () => {
		try {
			await api.get(IMAGES)
		} catch (err) {
			if (err.code === 'ERR_NETWORK') return true
			let statusCode = err.response.status
			return statusCode === 403 ? true : false
		}
	}

	useEffect(async () => {
		setOfflineOnlineStatus(await checkOnlineStatus())
	}, [])

	//checking if there's internet connection
	window.addEventListener('offline', async event => {
		setOfflineOnlineStatus(await checkOnlineStatus())
	})

	window.addEventListener('online', async event => {
		setOfflineOnlineStatus(await checkOnlineStatus())
	})

	window.addEventListener('load', async event => {
		setOfflineOnlineStatus(await checkOnlineStatus())
	})

	return (
		<View>
			{isOffline && (
				<View>
					<OfflineBar />
				</View>
			)}
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					backgroundColor: colors.primary,
					paddingVertical: 10
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{navigation.canGoBack() && (
							<Pressable onPress={() => navigation.goBack()}>
								<Ionicons name="arrow-back" size={26} style={{ marginRight: 10 }} color="white" />
							</Pressable>
						)}
						<Text style={{ color: 'white', fontSize: 20 }}>{title}</Text>
					</View>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{isSidebarOpen && <Input style={{ width: 250 }} onSubmitEditing={handleSearch} placeholder={'Search'} />}
						<ScheduleMenu />
						<SettingMenu handleAdminView={handleAdminView} isAdmin={isAdmin} />
						<Pressable onPress={handleLogout}>
							<Ionicons name="power" style={{ marginLeft: 20 }} size={30} color="white" />
						</Pressable>
						<Pressable onPress={handleHelp}>
							<Ionicons name="help" style={{ marginLeft: 20 }} size={30} color="white" />
						</Pressable>
					</View>
				</View>
			</View>
		</View>
	)
}
