import { Pressable, Image, View } from 'react-native'

export default function CategoryCard({ item }, { navigate }, deviceType) {
	const width = deviceType === 1 ? 230 : 190
	return (
		<Pressable onPress={() => navigate('PlaylistScreen', { categoryId: item.id, hasCopyPlaylistBtn: true })}>
			<View
				style={{
					width: width,
					borderRadius: 10,
					marginBottom: 15,
					// marginHorizontal: 15,
					overflow: 'hidden'
				}}
			>
				<Image
					style={{ width: width, height: width, borderRadius: 10, overflow: 'hidden' }}
					resizeMode="cover"
					onError={() => console.log('Image error')}
					source={{ uri: item.Photo }}
				/>
			</View>
		</Pressable>
	)
}
