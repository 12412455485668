import { ScrollView, Text, Image, Alert, View, Modal, FlatList } from 'react-native'
import React, { useState, useEffect } from 'react'
import { Input, TextArea } from '../../Components/Forms/Input'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { colors, INTERNAL_CONTAINER_URL, INTERNAL_PLAYLIST_GROUP_URL, INTERNAL_URL } from '../../Helpers/variables'
import * as ImagePicker from 'expo-image-picker'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import { SecondaryButton } from '../../Components/Buttons/Button'
import { Select } from '../Forms/Select'
import { trackStyles } from '../../Screens/TracksScreen/components/TrackStyles'
import api from '../../Helpers/api'
import useLayout from '../../Hooks/useLayout'
import { Ionicons } from '@expo/vector-icons'
import customAlert from '../../Helpers/alert'

export default function InternalPlaylistContainerModal({
	setModalVisible,
	playlistInfo,
	modalVisible,
	getPlaylist,
	processType = 'update',
	setProcessType
}) {
	// const [statePlaylistInfo, setStatePlaylistInfo] = useState(playlistInfo)
	const [name, setName] = useState(playlistInfo ? playlistInfo.name : '')
	const [message, setMessage] = useState({ error: false, message: '' })
	const [description, setDescription] = useState(playlistInfo ? playlistInfo.description : '')
	const [positionState, setPosition] = useState(playlistInfo.position ? playlistInfo.position : 0)

	const [theme, setTheme] = useState(playlistInfo ? playlistInfo.theme : '')
	const [visibility, setVisibility] = useState(playlistInfo ? playlistInfo.visibility : '')
	const [loading, setLoading] = React.useState(false)
	const [Photo, setPhoto] = useState(null)
	const { getTokens } = useTokens()
	const [newPlaylist, setNewPlaylist] = useState('')
	const [internalPlaylist, setInternalPlaylist] = useState([])
	const [internalPlaylistGroupsOriginal, setInternalPlaylistGroupsOriginal] = useState([])
	const [internalPlaylistGroupsSelected, setInternalPlaylistGroupsSelected] = useState([])
	const [internalPlaylistGroupsRemoved, setInternalPlaylistGroupsRemoved] = useState([])

	const [positionNumbers, setPositionNumbers] = useState([])

	const getInternalPlaylist = () => {
		let newItems = [
			{
				value: '',
				label: 'Tilfoj flere spilleliste'
			}
		]
		api.get(INTERNAL_URL).then(({ data, index }) => {
			data.map((item, index) => {
				newItems.push({
					value: item.id,
					label: item.name
				})
				if (index === data.length - 1) {
					setInternalPlaylist(newItems)
					if (playlistInfo.id) getInternalPlaylistGroups(newItems)
				}
			})
		}),
			({ response }) =>
				console
					.log(response)

					.catch(error => {
						// if (Platform.OS === 'web') Sentry.Browser.captureException(error)
						// else Sentry.Native.captureException(error)
					})
					.finally(() => setLoading(false))
	}

	const getInternalPlaylistGroups = internalPlaylistTemp => {
		api
			.get(INTERNAL_PLAYLIST_GROUP_URL + playlistInfo.id)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.position - b.position
				})

				setInternalPlaylistGroupsOriginal(data)
				setInternalPlaylistGroupsSelected(data)

				if (internalPlaylistTemp.length > 0) {
					data.map(item => {
						internalPlaylistTemp = internalPlaylistTemp.filter(x => x.value !== item.playlist)
					})
					setInternalPlaylist(internalPlaylistTemp)
				}
			})
			.catch(error => {
				// if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				// else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getInternalPlaylist()

		let positionNumbersTemp = []
		for (let i = 0; i < 101; i++) {
			positionNumbersTemp.push({
				value: String(i),
				label: String(i)
			})
		}
		setPositionNumbers(positionNumbersTemp)
	}, [playlistInfo])

	const onChangeSelectNewPlaylist = e => {
		if (e !== '') {
			let internalPlaylistGroupsSelectedTemp = internalPlaylistGroupsSelected
			let internalPlaylistTemp = internalPlaylistGroupsSelected.filter(x => x.playlist === e)[0]
			if (internalPlaylistTemp === undefined) {
				let checkItemExist = internalPlaylistGroupsOriginal.filter(x => x.playlist === e)[0]
				if (checkItemExist) {
					checkItemExist.processType = 'update'
					internalPlaylistGroupsSelectedTemp.push(checkItemExist)
				} else {
					internalPlaylistGroupsSelectedTemp.push({
						id: 'playlist' + e,
						playlist: e,
						playlist_name: internalPlaylist.filter(x => x.value === parseInt(e))[0].label,
						position: 0,
						processType: 'add'
					})
				}

				internalPlaylistGroupsSelectedTemp = internalPlaylistGroupsSelectedTemp.sort(function (a, b) {
					return a.position - b.position
				})
				setInternalPlaylistGroupsSelected(internalPlaylistGroupsSelectedTemp)
			}
		}

		setNewPlaylist(e)
	}

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0,
			base64: false
		})

		console.log(result)

		if (!result.cancelled) {
			let uri = result.uri

			if (uri) {
				setPhoto(Platform.OS === 'ios' ? uri.replace('file://', '') : uri)
			} else {
				const extension = uri.substring(uri.lastIndexOf('.') + 1, uri.length)
				const type = result.type + '/' + extension
				const name = 'test'
				setPhoto({ uri, type, name })
			}
		}
	}

	const fetchImageFromUri = async uri => {
		const response = await fetch(uri)
		const blob = await response.blob()
		return blob
	}

	const onChangePosition = (e, item) => {
		let selectedItems = internalPlaylistGroupsSelected.filter(x => x.id === item.id)[0]
		let selectedItemsTemp = internalPlaylistGroupsSelected.filter(x => x.id !== item.id)
		selectedItems.position = parseInt(e)
		selectedItemsTemp.push(selectedItems)
		selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
			return a.position - b.position
		})
		setInternalPlaylistGroupsSelected(selectedItemsTemp)
	}

	const deletePlaylistGroup = item => {
		let selectedItemsTemp = internalPlaylistGroupsSelected.filter(x => x.playlist !== item.playlist)
		selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
			return a.position - b.position
		})
		setInternalPlaylistGroupsSelected(selectedItemsTemp)

		let removedSelectedItemsTemp = internalPlaylistGroupsRemoved
		let checkItemExist = internalPlaylistGroupsOriginal.filter(x => x.playlist === item.playlist)[0]
		if (checkItemExist) {
			removedSelectedItemsTemp.push(checkItemExist)
			setInternalPlaylistGroupsRemoved(removedSelectedItemsTemp)
		}

		let internalPlaylistTemp = internalPlaylist
		let temp = internalPlaylistTemp.filter(x => x.value === item.playlist)[0]
		if (temp === undefined)
			internalPlaylistTemp.push({
				value: item.playlist,
				label: item.playlist_name
			})

		setInternalPlaylist(internalPlaylistTemp)
	}

	const handleUpdateContainer = access => {
		let position = parseInt(positionState)
		axios
			.put(
				INTERNAL_CONTAINER_URL + playlistInfo.id,
				{ name, description, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				// console.log(response)
				if (response.status === 200) {
					console.log('UPDATED')
					customAlert('Opdater spilleliste', 'Opdateret', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					console.log('FAILED TO UPDATE')
					customAlert('Opdater spilleliste', 'Kunne ikke opdateres', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const isFieldsValid = () => {
		if (name && description) {
			if (name.trim() === '' || description.trim() === '') {
				setMessage({ error: true, message: 'Udfyld venligst alle felter' })
				setLoading(false)
				return false
			}
		} else {
			setMessage({ error: true, message: 'Udfyld venligst alle felter' })
			setLoading(false)
			return false
		}

		if (isNaN(positionState)) {
			setMessage({ error: true, message: 'Positionen skal være numerisk' })
			setLoading(false)
			return false
		}

		return true
	}

	const handleUpdate = async () => {
		setLoading(true)

		if (!isFieldsValid()) return

		const { access } = await getTokens()

		//update internal playlist container
		handleUpdateContainer(access)

		internalPlaylistGroupsSelected.map(item => {
			if (item.processType === 'add') {
				//add new playlist group to container
				postPlaylistGroup(item, access)
			} else {
				//update playlist group
				updatePlaylistGroup(item, access)
			}
		})

		internalPlaylistGroupsRemoved.map(item => {
			//delete playlist group
			removePlaylistGroup(item, access)
		})

		getPlaylist()
		setName('')
		setDescription('')
	}

	const postPlaylistGroup = (item, access, internal_container_group = playlistInfo.id) => {
		let playlist = item.playlist
		let description = item.description
		let position = item.position

		axios
			.post(
				INTERNAL_PLAYLIST_GROUP_URL + 'playlist',
				{ playlist, description, internal_container_group, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				// console.log(response)
				if (response.status === 201) {
					console.log('ADDED')
				} else {
					console.log('FAILED TO ADD')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const updatePlaylistGroup = (item, access) => {
		let playlist = item.playlist
		let description = item.description
		let internal_container_group = playlistInfo.id
		let position = item.position
		axios
			.put(
				INTERNAL_PLAYLIST_GROUP_URL + 'playlist/' + item.id,
				{ playlist, description, internal_container_group, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				// console.log(response)
				if (response.status === 200) {
					console.log('UPDATED')
				} else {
					console.log('FAILED TO UPDATE')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const removePlaylistGroup = (item, access) => {
		axios
			.delete(INTERNAL_PLAYLIST_GROUP_URL + 'playlist/' + item.id, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(response => {
				// console.log(response)
				if (response.status === 204) {
					console.log('DELETED')
				} else {
					console.log('FAILED TO DELETE')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
			})
	}

	const handleSubmit = async () => {
		setLoading(true)

		if (!isFieldsValid()) return

		const { access } = await getTokens()

		//update internal playlist container
		handleAddContainer(access)
	}

	const handleAddContainer = access => {
		let position = parseInt(positionState)
		axios
			.post(
				INTERNAL_CONTAINER_URL,
				{ name, description, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				// console.log(response)
				if (response.status === 201) {
					console.log('ADDED')
					customAlert('Opret spilleliste', 'Tilføjet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])

					internalPlaylistGroupsSelected.map(item => {
						if (item.processType === 'add') {
							//add new playlist group to container
							postPlaylistGroup(item, access, response.data.id)
						} else {
							//update playlist group
							updatePlaylistGroup(item, access)
						}
					})

					internalPlaylistGroupsRemoved.map(item => {
						//delete playlist group
						removePlaylistGroup(item, access)
					})

					getPlaylist()
					setName('')
					setDescription('')
				} else {
					console.log('FAILED')
					customAlert('Opret spilleliste', 'Kunne ikke tilføje', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(false)
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<ScrollView>
							<Input placeholder={'Navn'} value={name} onChangeText={text => setName(text)} />
							<TextArea
								placeholder={'Beskrivelse'}
								value={description}
								numberOfLines={4}
								onChangeText={text => setDescription(text)}
							/>

							<Input placeholder={'Position'} value={String(positionState)} onChangeText={text => setPosition(text)} />

							<PrimaryButton title={'Vælg et billede fra kamerarullen'} onPress={pickImage} />
							{Photo && <Image source={{ uri: Photo.uri }} style={{ width: 200, height: 200 }} />}

							<View style={{ flexDirection: 'row' }}>
								<Text style={[{ marginRight: 40, marginTop: 10, color: 'white' }, trackStyles.label]}>Playlist</Text>
								<Select onValueChange={onChangeSelectNewPlaylist} value={newPlaylist} items={internalPlaylist} />
							</View>
							<View
								style={{
									borderBottomColor: 'white',
									borderBottomWidth: 2,
									marginTop: 10
								}}
							/>
							<FlatList
								data={internalPlaylistGroupsSelected}
								ListEmptyComponent={
									<View>
										<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No playlist groups...</Text>
									</View>
								}
								renderItem={({ item }) => (
									<View
										style={{ flexDirection: 'row', width: 300, marginTop: 10 }}
										key={'selectPlaylistPosition' + item.id}
									>
										<View style={{ width: 240 }}>
											<Text style={[{ marginTop: 10, color: 'white', marginRight: 20 }, trackStyles.label]}>
												{item.playlist_name}
											</Text>
										</View>
										<View style={{ width: 40, marginRight: 10 }}>
											<Select
												onValueChange={e => {
													onChangePosition(e, item)
												}}
												value={String(item.position)}
												items={positionNumbers}
												width={40}
												key={'selectPosition' + item.id}
											/>
										</View>
										<View style={{ marginRight: 20 }}>
											<Ionicons
												name="trash"
												size={20}
												color={'white'}
												style={{ marginTop: 10 }}
												onPress={() => deletePlaylistGroup(item)}
											/>
										</View>
									</View>
								)}
								showsVerticalScrollIndicator={false}
								showsHorizontalScrollIndicator={false}
								keyExtractor={item => item.id}
							/>
							<PrimaryButton
								style={{ marginTop: 30, width: 300 }}
								title={processType === 'update' ? 'Opdater spilleliste' : 'Opret spilleliste'}
								loading={loading}
								onPress={() => {
									processType === 'update' ? handleUpdate() : handleSubmit()
								}}
							/>
							<SecondaryButton title={'Cancel'} onPress={() => setModalVisible(false)} style={{ width: 300 }} />
						</ScrollView>
						<Text style={{ textAlign: 'center', color: message.error ? 'red' : 'green', marginVertical: 10 }}>
							{message.message}
						</Text>
					</View>
				</View>
			</Modal>
		</View>
	)
}
