import React from 'react'
import { View } from 'react-native'

export default function OfflineBar() {
	return (
		<View style={{ textAlign: 'center', backgroundColor: 'red', width: '100%', color: 'white', fontWeight: 'bold' }}>
			Du er offline. Tjek dit internet.
		</View>
	)
}
