import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, FlatList, useWindowDimensions, Pressable, Platform, Alert } from 'react-native'
import * as Sentry from 'sentry-expo'
import { Ionicons } from '@expo/vector-icons'
import useAxios from '../../Hooks/useAxios'
import { trackStyles } from '../TracksScreen/components/TrackStyles'
import Loader from '../../Components/Loader/Loader'
import { COPYPLAYLIST_ASSIGNED_URL, colors, USER_PLAYLIST_URL } from '../../Helpers/variables'
import { PlaylistCard } from '../../Components/Playlists/PlaylistCards'
import useLayout from '../../Hooks/useLayout'
import { CreatePlaylistModal } from '../TracksScreen/components/CreatePlaylistModal'
import { FlatGrid } from 'react-native-super-grid'
import customAlert from '../../Helpers/alert'
import { ConfirmationModal } from '../../Components/Modals/ConfirmationModal'
import { useIsFocused } from '@react-navigation/native'

export default function MyPlaylistScreen({ navigation }) {
	const [userPlaylists, setUserPlaylists] = useState([])
	const [assignedPlaylists, setAssignedPlaylists] = useState([])
	const [loading, setLoading] = useState(true)
	const { deviceType } = useLayout()
	const [modalVisible, setModalVisible] = useState(false)
	const [playlistInfo, setPlaylistInfo] = useState({})
	const [removePlaylistConfirmModal, setRemovePlaylistConfirmModal] = useState(false)
	const [itemPlaylist, setItemPlaylist] = useState(undefined)

	const api = useAxios()
	// check if screen is focused
	const isFocused = useIsFocused()

	useEffect(() => {
		isFocused && refreshView()
	}, [isFocused])

	const updateUserPlaylists = (id, name, description) => {
		let userPlaylistsTemp = userPlaylists.filter(x => x.id !== id)
		userPlaylistsTemp.push({ id: id, name: name, description: description })
		userPlaylistsTemp = userPlaylistsTemp.sort(function (a, b) {
			return a.id - b.id
		})
		setUserPlaylists(userPlaylistsTemp)
	}

	const refreshView = () => {
		Promise.all([USER_PLAYLIST_URL, COPYPLAYLIST_ASSIGNED_URL].map(endpoint => api.get(endpoint)))
			.then(([{ data: user }, { data: assigned }]) => {
				setAssignedPlaylists(assigned)
				user = user.sort(function (a, b) {
					return a.id - b.id
				})
				setUserPlaylists(user)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	const handlePlaylistPress = (playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable = false) => {
		navigation.navigate('TracksScreen', { playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable })
	}

	const windowHeight = useWindowDimensions().height

	const handleDeletePlaylist = () => {
		let item = itemPlaylist
		api
			.delete(USER_PLAYLIST_URL + '/' + item.id)
			.then(response => {
				if (response.status === 204) {
					let newUserPlaylist = userPlaylists
					newUserPlaylist = newUserPlaylist.filter(x => x.id !== item.id)
					setUserPlaylists(newUserPlaylist)

					customAlert('Slet ', 'Fjernet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					customAlert('Slet spillelister', 'Kunne ikke fjernes', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				customAlert('Slet spillelister', 'Kunne ikke fjernes', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
				setRemovePlaylistConfirmModal(false)
			})
	}

	const cancelCallbackRemovePlaylist = () => {
		setRemovePlaylistConfirmModal(false)
	}

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ maxHeight: windowHeight - 100, paddingVertical: 10 }}>
			<View style={{ padding: 10, marginBottom: 10, backgroundColor: colors.secondary, borderRadius: 5 }}>
				<Text style={{ color: colors.accent }}>Tildelte spillelister</Text>
			</View>
			<View>
				<FlatGrid
					itemDimension={200}
					data={assignedPlaylists}
					renderItem={item => (
						<PlaylistCard
							key={item.id}
							item={item}
							width={deviceType === 1 ? 230 : 190}
							handlePlaylistPress={handlePlaylistPress}
						/>
					)}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
						</View>
					}
					keyExtractor={item => item.id}
					style={{ flex: 1 }}
					contentContainerStyle={{ paddingBottom: 20 }}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				/>
			</View>

			<View style={{ padding: 10, marginBottom: 10, backgroundColor: colors.secondary, borderRadius: 5 }}>
				<Text style={{ color: colors.accent }}>Mine spillelister</Text>
			</View>

			<View style={trackStyles.trackList}>
				<FlatList
					data={userPlaylists}
					renderItem={playlist => (
						<View style={trackStyles.trackRow} key={playlist.item.id}>
							<Pressable
								onPress={() =>
									handlePlaylistPress(playlist.item.id, playlist.item.photo, playlist.item.name, true, true)
								}
								style={trackStyles.songContainer}
							>
								<View>
									<Text style={{ color: 'white', fontSize: 14 }}>{playlist.item.name}</Text>
									<Text style={{ color: 'white', fontSize: 10 }}>{playlist.item.description}</Text>
								</View>
							</Pressable>
							<View style={trackStyles.leftContainer}>
								<Pressable
									onPress={() => {
										setModalVisible(true)
										setPlaylistInfo(playlist.item)
									}}
									style={{ marginLeft: 10 }}
								>
									<Ionicons name="create" size={25} color={'white'} />
								</Pressable>
								<Pressable
									onPress={() => {
										setRemovePlaylistConfirmModal(true)
										setItemPlaylist(playlist.item)
									}}
									style={{ marginLeft: 10 }}
								>
									<Ionicons name="trash" size={25} color={'white'} />
								</Pressable>
							</View>
						</View>
					)}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
						</View>
					}
					keyExtractor={item => item.id}
					numColumns={1}
					// contentContainerStyle={{ paddingBottom: 20 }}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				/>
			</View>

			<View>
				{modalVisible && (
					<CreatePlaylistModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						playlistInfo={playlistInfo}
						processType={'update'}
						updateUserPlaylists={updateUserPlaylists}
					/>
				)}
			</View>

			{removePlaylistConfirmModal && (
				<View>
					<ConfirmationModal
						handleSubmit={handleDeletePlaylist}
						modalVisible={removePlaylistConfirmModal}
						cancelCallback={cancelCallbackRemovePlaylist}
						description={'Er du sikker på, at du vil fjerne denne spilleliste?'}
					/>
				</View>
			)}
		</ScrollView>
	)
}
