import { useEffect, useState } from 'react'
import { View, Text, Switch, ActivityIndicator, Pressable, useWindowDimensions } from 'react-native'
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { Ionicons } from '@expo/vector-icons'
import Slider from '@react-native-community/slider'
import useAxios from '../../Hooks/useAxios'
import { colors, LOOP_SCHEDULING, LOOP_SETTING } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import customAlert from '../../Helpers/alert'
import { MenuDivider } from 'react-native-material-menu'
import { Input } from '../Forms/Input'
import { TimePickerInput } from '../Forms/TimePickerInput'
import { convertDateToTimeHHmmss } from '../../Helpers/timeConverter'

export const ScheduleMenu = ({ handleAdminView, isAdmin }) => {
	const api = useAxios()
	const [loading, setLoading] = useState(false)
	const [formLoading, setFormLoading] = useState(false)
	const [zoneOneTime, setZoneOneTime] = useState('05:00:00')
	const [zoneTwoTime, setZoneTwoTime] = useState('')
	const [zoneThreeTime, setZoneThreeTime] = useState('')
	const [isOpened, setIsOpened] = useState(false)
	// convertDateToTimeHHmmss(new Date())

	useEffect(async () => {
		try {
			setFormLoading(true)
			const { data: loop_schedule } = await api.get(LOOP_SCHEDULING)
			loop_schedule.map(item => {
				if (item.settings_type === 2) setZoneTwoTime(item.start_time)
				else if (item.settings_type === 3) setZoneThreeTime(item.start_time)
				// else {
				// 	console.log('UNKNOWN LOOP SCHEDULING SETTINGS TYPE')
				// }
			})
		} catch (err) {
			console.log(err)
		} finally {
			setFormLoading(false)
		}
	}, [])

	const addLoopScheduling = (settings_type, start_time) => {
		api.post(LOOP_SCHEDULING, { settings_type: settings_type, start_time: start_time }).then(
			({ data }) => {
				if (settings_type === 1 && zoneTwoTime !== '') addLoopScheduling(2, zoneTwoTime)
				else if (settings_type === 2 && zoneThreeTime !== '') addLoopScheduling(3, zoneThreeTime)

				if (settings_type === 1) {
					setIsOpened(false)
					customAlert('Skift tidsplan', 'Gemt', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				}
			},
			err => {
				console.log(err)
			}
		)
	}

	const handleFormSubmit = () => {
		setLoading(true)
		if (zoneOneTime !== '') addLoopScheduling(1, zoneOneTime)
		setLoading(false)
	}

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<Menu
				opened={isOpened}
				onBackdropPress={() => {
					setIsOpened(false)
				}}
			>
				<MenuTrigger
					onPress={() => {
						setIsOpened(true)
					}}
				>
					<Ionicons name="time-outline" style={{ marginLeft: 20 }} size={30} color="white" />
				</MenuTrigger>
				<MenuOptions style={{ backgroundColor: '#111111', padding: 15 }}>
					{formLoading ? (
						<ActivityIndicator color={colors.accent} />
					) : (
						<View>
							<View style={{ flexDirection: 'row', paddingTop: 15 }}>
								<Text style={{ color: 'white', marginTop: 15 }}>Zone 1</Text>
								<TimePickerInput onChange={setZoneOneTime} value={zoneOneTime} isDisabled={true} />
							</View>
							<View style={{ flexDirection: 'row', paddingTop: 15 }}>
								<Text style={{ color: 'white', marginTop: 15 }}>Zone 2</Text>
								<TimePickerInput
									onChange={setZoneTwoTime}
									value={zoneTwoTime}
									isDisabled={zoneOneTime === '' ? true : false}
								/>
							</View>
							<View style={{ flexDirection: 'row', paddingTop: 15 }}>
								<Text style={{ color: 'white', marginTop: 15 }}>Zone 3</Text>
								<TimePickerInput
									onChange={setZoneThreeTime}
									value={zoneThreeTime}
									isDisabled={zoneTwoTime === '' ? true : false}
								/>
							</View>
							<PrimaryButton onPress={() => handleFormSubmit()} title={'Save'} loading={loading} />
						</View>
					)}
				</MenuOptions>
			</Menu>
		</View>
	)
}
