import { useEffect, useState } from 'react'
import { View, Text, useWindowDimensions, Platform, ScrollView } from 'react-native'
import * as Sentry from 'sentry-expo'
import CategoryCard from './components/CategoryCard'
import { FlatGrid } from 'react-native-super-grid'

import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'

import { colors, MAIN_CONTAINER_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import useSound from '../../Hooks/useSound'

export default function HomeScreen({ navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()
	const { deviceType } = useLayout()

	const [containers, setContainer] = useState([])
	const [loading, setLoading] = useState(true)

	const getContainers = async () => {
		try {
			const { data } = await api.get(MAIN_CONTAINER_URL)
			setContainer(data)
		} catch (err) {
			console.log('Home handleForbiddenResponse: ' + err)
			if (Platform.OS === 'web') Sentry.Browser.captureException(err)
			else Sentry.Native.captureException(err)
		}
		setLoading(false)
		return
	}

	useEffect(() => {
		getContainers()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ backgroundColor: colors.primary, height: height - 80 }}>
			<Heading style={{ color: colors.accent }}>Kategorier</Heading>
			<FlatGrid
				data={containers}
				renderItem={item => CategoryCard(item, navigation, deviceType)}
				ListEmptyComponent={<Text style={{ color: 'white' }}>No data...</Text>}
				keyExtractor={item => item.id}
				itemDimension={200}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingVertical: 20 }}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			/>
		</ScrollView>
	)
}
