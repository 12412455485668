import React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { View, Text, ScrollView, Alert } from 'react-native'
import { colors, PLAYLIST_TRACKS_URL } from '../../../Helpers/variables'
import { CreatePlaylistModal } from './CreatePlaylistModal'
import { trackStyles } from './TrackStyles'
import axios from 'axios'
import useTokens from '../../../Hooks/useTokens'
import customAlert from '../../../Helpers/alert'
import { Menu } from 'react-native-paper'
import { TouchableHighlight } from 'react-native-web'

export const PlaylistTrackMenu = ({ track, setMenuVisible, userPlaylists, updateUserPlaylists }) => {
	const [playlistVisible, setPlaylistVisible] = React.useState(false)
	const [modalVisible, setModalVisible] = React.useState(false)
	const { getTokens } = useTokens()

	const hidePlaylist = () => {
		setPlaylistVisible(false)
		setMenuVisible(false)
	}

	const addToPlaylist = async (playlistId, track) => {
		const { access } = await getTokens()
		axios
			.post(
				PLAYLIST_TRACKS_URL + playlistId,
				{ track },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					customAlert('Tilføj til spilleliste', 'Tilføjet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					customAlert('Tilføj til spilleliste', 'Kunne ikke tilføje', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				if (err.message === 'Request failed with status code 400') {
					customAlert('Tilføj til spilleliste', 'Nummeret findes allerede i spilleliste', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
	}

	return (
		<View key={'playlistviewkey' + track.id}>
			<Menu
				contentStyle={{ backgroundColor: colors.secondary, minWidth: 193 }}
				visible={playlistVisible}
				onDismiss={hidePlaylist}
				anchor={
					<TouchableHighlight
						onPress={() => {
							setPlaylistVisible(true)
						}}
					>
						<Text style={trackStyles.menuTextView}>Tilføj til spilleliste</Text>
					</TouchableHighlight>
				}
				onRequestClose={() => setPlaylistVisible(false)}
			>
				<TouchableHighlight onPress={() => setPlaylistVisible(false)}>
					<Ionicons name="arrow-back" size={25} color={'white'} style={{ marginLeft: 15 }} />
				</TouchableHighlight>
				<TouchableHighlight
					key={'playlistkey00'}
					onPress={() => {
						setModalVisible(true)
					}}
				>
					<Text style={[trackStyles.menuTextView, { margin: 15, fontSize: 15, fontWeight: 'bold' }]}>
						Opret ny spilleliste
					</Text>
				</TouchableHighlight>
				<ScrollView style={{ maxHeight: 200 }}>
					{userPlaylists.map(playlist => (
						<TouchableHighlight
							key={'playlistkey' + playlist.id}
							onPress={() => {
								addToPlaylist(playlist.id, track.id)
								setPlaylistVisible(false)
								setMenuVisible(false)
							}}
						>
							<Text style={trackStyles.menuTextView}>{playlist.name}</Text>
						</TouchableHighlight>
					))}
				</ScrollView>
				<View>
					{modalVisible && (
						<CreatePlaylistModal
							modalVisible={modalVisible}
							setModalVisible={setModalVisible}
							updateUserPlaylists={updateUserPlaylists}
						/>
					)}
				</View>
			</Menu>
		</View>
	)
}
