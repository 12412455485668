import React, { useState } from 'react'
import { Ionicons } from '@expo/vector-icons'
// import { Menu } from 'react-native-material-menu'
import { View, Text } from 'react-native'
import { trackStyles } from './TrackStyles'
import truncateStr from '../../../Helpers/truncateStr'
import { PlaylistTrackMenu } from './PlaylistTrackMenu'
import { RemoveTrackPlaylist } from './RemoveTrackPlaylist'
import { Menu } from 'react-native-paper'
import { colors } from '../../../Helpers/variables'

export const TrackMenu = ({
	track,
	playlistTrackId,
	isTracksRemovable,
	setTrackList,
	tracksList,
	userPlaylists,
	updateUserPlaylists
}) => {
	const [menuVisible, setMenuVisible] = useState(false)

	const hideMenu = () => setMenuVisible(false)

	const showMenu = () => setMenuVisible(true)

	return (
		<Menu
			contentStyle={{ backgroundColor: colors.secondary }}
			visible={menuVisible}
			onDismiss={hideMenu}
			anchor={
				<Ionicons name="ellipsis-vertical" size={25} style={{ marginLeft: 20 }} color={'white'} onPress={showMenu} />
			}
		>
			<View style={{ backgroundColor: colors.secondary }}>
				<View style={{ flexDirection: 'row', height: 'auto' }}>
					<Ionicons name="musical-notes" size={40} color={'white'} style={{ marginTop: 7 }} />
					<View style={{ marginTop: 5, marginLeft: 5, width: '100%' }}>
						<Text style={{ color: 'white', fontSize: 14, width: 150 }}>{truncateStr(track.song_title, 30)}</Text>
						<Text style={[trackStyles.albumTitle, { width: 150 }]}>{truncateStr(track.artist_name, 30)}</Text>
					</View>
				</View>
				<View style={{ marginTop: 20 }}>
					<PlaylistTrackMenu
						key={'playlisttrackview' + track.id}
						track={track}
						menuVisible={menuVisible}
						setMenuVisible={setMenuVisible}
						userPlaylists={userPlaylists}
						updateUserPlaylists={updateUserPlaylists}
					/>
					{isTracksRemovable && (
						<RemoveTrackPlaylist
							playlistTrackId={playlistTrackId}
							tracksList={tracksList}
							setTrackList={setTrackList}
						/>
					)}
				</View>
			</View>
		</Menu>
	)
}
