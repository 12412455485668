import { Platform } from 'react-native'

export const colors = {
	primary: '#151a28',
	secondary: '#1E273D',
	accent: '#ea580c'
}

export const SENTRY_DSN = 'https://2ad40b193c3d441bbecfa28c0fc6ecdb@o1228491.ingest.sentry.io/6374247'

// export const URL = 'http://127.0.0.1:8000'
export const URL = 'https://www.musicminduat.dk'

export const CORS_URL = ''
export const BASE_API_URL = Platform.OS === 'web' ? CORS_URL + URL : URL

export const LOGIN_URL = BASE_API_URL + '/api/token/obtain/'
export const REFRESH_URL = BASE_API_URL + '/api/token/refresh/'

export const MAIN_CONTAINER_URL = BASE_API_URL + '/music/ContainerGroups/'

export const PLAYLIST_GROUP_URL = BASE_API_URL + '/music/PlaylistGroups/'
export const PLAYLIST_TRACKS_URL = BASE_API_URL + '/music/musicmind-playlist/tracks/'

export const CREATE_PLAYLIST_URL = BASE_API_URL + '/music/playlist'
export const USER_PLAYLIST_URL = BASE_API_URL + '/music/playlist'

export const TIMER_ACQUISITION_URL = BASE_API_URL + '/music/timer-acquisition/'
export const MEDIAPLAYER_ACQUISITION_URL = BASE_API_URL + '/music/mediaplayer-acquisition/'

export const INTERNAL_CONTAINER_URL = BASE_API_URL + '/music/InternalContainerGroups/'
export const INTERNAL_URL = BASE_API_URL + '/music/Internal/'
export const INTERNAL_PLAYLIST_GROUP_URL = BASE_API_URL + '/music/InternalPlaylistGroups/'

export const SEARCH_URL = BASE_API_URL + '/music/musicmindsearch/'
export const ARTIST_TRACKS_URL = BASE_API_URL + '/music/musicmind-artist/'
export const ALBUM_TRACKS_URL = BASE_API_URL + '/music/musicmind-album/'

export const PLAYLIST_TRACKS_EDIT_URL = BASE_API_URL + '/music/musicmind-playlist/tracks/edit/'

export const COPYPLAYLIST_ASSIGNED_URL = BASE_API_URL + '/music/copy-playlist-assigned/'
export const REVERSEUSERLOOKUP_URL = BASE_API_URL + '/music/reverse-userlookup/'
export const ASSIGNED_URL = BASE_API_URL + '/music/assigned/'

export const USERTYPE_URL = BASE_API_URL + '/api/user-type/'

export const CHANGEPASS_URL = BASE_API_URL + '/api/change_password/'
export const CREATEUSER_URL = BASE_API_URL + '/api/user/create/'
export const USERLIST_URL = BASE_API_URL + '/api/user-list/'
export const USERLISTSOONTOEXPIRE_URL = BASE_API_URL + '/api/user-soon-expire/'
export const USERLISTDEACTIVATED_URL = BASE_API_URL + '/api/user-deaktivated/'
export const ROYALTY_LOGGING_URL = BASE_API_URL + '/music/Logging/'

export const GET_USERNAME = BASE_API_URL + '/api/user-name'
export const PLAYLIST_BARIX = CORS_URL + 'https://loopstreaming.net/barix/playlist/'
export const CREATE_PLAYLIST_BARIX = CORS_URL + 'https://www.loopstreaming.net/barix/device/create/'
export const LOOP_SETTING = BASE_API_URL + '/music/loop_settings/'
export const GET_LOOP_USER_SETTING = BASE_API_URL + '/music/loop_settings_POST_PUT/'
export const LOOP_STREAMING = BASE_API_URL + '/music/loop_streaming/'
export const LOOP_BOOL = BASE_API_URL + '/music/loop_bool/'
export const LOOP = BASE_API_URL + '/instorex/loop/'
export const LOOP_DEVICE = BASE_API_URL + '/instorex/loop_device/'
export const IMAGES = BASE_API_URL + '/images/'

export const SONGREQUEST = BASE_API_URL + '/music/songrequest/'
export const FEEDBACKREQUEST = BASE_API_URL + '/music/feedbackrequest/'

export const LOOP_SCHEDULING = BASE_API_URL + '/music/loop_scheduling/'
