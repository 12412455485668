import { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import ErrorBoundary from 'react-native-error-boundary'
import * as Sentry from 'sentry-expo'
import { MenuProvider } from 'react-native-popup-menu'

import RootStack from './Components/Stacks/RootStack'
import MainStack from './Components/Stacks/MainStack'
import { AuthContext } from './Context/AuthContext/AuthContext'
import AuthService from './Services/AuthService'
import { LayoutProvider } from './Context/LayoutContext/LayoutContext'
import { SoundProvider } from './Context/SoundContext/SoundContext'
import { SENTRY_DSN, USERTYPE_URL } from './Helpers/variables'
import AdminMainStack from './Components/Stacks/AdminMainStack'
import api from './Helpers/api'
import Loader from './Components/Loader/Loader'
import useTokens from './Hooks/useTokens'

Sentry.init({
	dsn: SENTRY_DSN,
	debug: false // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
})

export default function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [isAdminViewOpen, setIsAdminViewOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const { getTokens } = useTokens()

	const getUsertype = async () => {
		const { access } = await getTokens()
		const { data } = await api.get(USERTYPE_URL, {
			headers: { Authorization: `JWT ${access}` }
		})
		if (data.usertype === 4 || data.usertype === 5) setIsAdmin(true)
	}

	useEffect(async () => {
		AuthService.isLoggedIn()
			.then(res => {
				setIsLoggedIn(res)
				if (res) getUsertype()
			})
			.catch(err => {
				console.log(err)
			})
			.finally(() => {
				setIsLoading(false)
			})

		try {
			await ScreenOrientation.unlockAsync()
		} catch (err) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(err)
			else Sentry.Native.captureException(err)
		}
	}, [])

	const handleError = (error, stackTrace) => {
		if (Platform.OS === 'web') Sentry.Browser.captureException(error)
		else Sentry.Native.captureException(error)
	}

	return (
		<ErrorBoundary onError={handleError}>
			<AuthContext.Provider
				value={{ isLoggedIn, setIsLoggedIn, isAdmin, setIsAdmin, isAdminViewOpen, setIsAdminViewOpen }}
			>
				<LayoutProvider>
					<SoundProvider>
						<MenuProvider>
							{isLoading ? (
								<Loader />
							) : (
								<NavigationContainer>
									{!isLoggedIn ? <RootStack /> : isAdmin && isAdminViewOpen ? <AdminMainStack /> : <MainStack />}
								</NavigationContainer>
							)}
						</MenuProvider>
					</SoundProvider>
				</LayoutProvider>
			</AuthContext.Provider>
		</ErrorBoundary>
	)
}
