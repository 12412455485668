import React, { useEffect, useState } from 'react'

import { ScrollView, View, useWindowDimensions, FlatList, Text } from 'react-native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { Input, TextArea } from '../../Components/Forms/Input'
import { SONGREQUEST, GET_USERNAME } from '../../Helpers/variables'
import customAlert from '../../Helpers/alert'
import api from '../../Helpers/api'
import axios from 'axios'
import base64 from 'react-native-base64'

export default function SongRequestScreen() {
	const [artistInput, setArtistInput] = useState('')
	const [albumInput, setAlbumInput] = useState('')
	const [titleInput, setTitleInput] = useState('')
	const [messageInput, setMessageInput] = useState('')
	const [loading, setLoading] = useState(false)
	const windowHeight = useWindowDimensions().height
	const [errorMessage, setErrorMessage] = useState('')

	const createJiraIssue = () => {
		const bodyData = {
			fields: {
				project: {
					key: 'LFA'
				},
				summary: 'TEST 99',
				description: 'REST APIs are great.',
				issuetype: {
					name: 'Bug'
				},
				sprint: {
					name: 'LFA Sprint 1'
				}
			}
		}

		axios
			.post(
				'https://www.musicmind-cors.dk/https://auditive.atlassian.net/rest/api/2/issue/',
				JSON.stringify(bodyData),
				{
					headers: {
						Authorization: `Basic${base64.encode('annborreo@instorex.dk:THD87cBueEnrXTUGtosPF094').toString('base64')}`,
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'X-Atlassian-Token': 'no-check'
					}
				}
			)
			.then(response => {
				console.log(response.status)
				if (response.status === 201) {
					console.log('success: ' + item.name)
				} else {
					console.log('failed: ' + item.name)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true)
		// createJiraIssue()

		if (artistInput === '' || albumInput === '' || titleInput === '' || messageInput === '') {
			setErrorMessage('Alle input er påkrævet')
			setLoading(false)
			return
		}

		try {
			let artist = artistInput
			let album = albumInput
			let track = titleInput
			let message = messageInput

			let { status: status } = await api.post(SONGREQUEST, {
				artist,
				album,
				track,
				message
			})

			if (status === 201) {
				customAlert('Anmod om en sang', 'Email sendt!', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				setAlbumInput('')
				setArtistInput('')
				setTitleInput('')
				setMessageInput('')
			} else
				customAlert('Anmod om en sang', 'Kunne ikke sende e-mail!', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
		} catch (err) {
			console.log(err)
			customAlert('Anmod om en sang', 'Kunne ikke sende e-mail!', [
				{ text: 'OK', onPress: () => console.log('OK Pressed') }
			])
		} finally {
			setLoading(false)
			setErrorMessage('')
		}
	}

	return (
		<ScrollView style={{ maxHeight: windowHeight - 100, marginLeft: 10 }}>
			<Input onChangeText={text => setArtistInput(text)} placeholder={'Kunstner'} value={artistInput} />
			<Input
				style={{ marginTop: 10 }}
				onChangeText={text => setAlbumInput(text)}
				placeholder={'Album'}
				value={albumInput}
			/>
			<Input
				style={{ marginTop: 10 }}
				onChangeText={text => setTitleInput(text)}
				placeholder={'Sangtitel'}
				value={titleInput}
			/>
			<TextArea
				style={{ marginTop: 10 }}
				onChangeText={text => setMessageInput(text)}
				placeholder={'Besked'}
				value={messageInput}
				numberOfLines={4}
			/>
			{errorMessage && <Text style={{ fontSize: 14, color: 'red' }}>{errorMessage}</Text>}
			<PrimaryButton onPress={handleFormSubmit} title={'Sende'} loading={loading} />
		</ScrollView>
	)
}
