import { useState, useEffect } from 'react'
import { View } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors, PLAYLIST_TRACKS_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useTokens from '../../Hooks/useTokens'
import PlaylistDetails from './components/PlaylistDetails'
import TrackList from './components/TrackList'

export default function TracksScreen({ route, navigation, ...props }) {
	const api = useAxios()

	const [trackList, setTrackList] = useState([])
	const [userPlaylists, setUserPlaylists] = useState([])
	const { playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable, hasCopyPlaylistBtn } = route.params

	const [loading, setLoading] = useState(true)
	const { getTokens } = useTokens()
	const [accessState, setAccessState] = useState('')

	const updateUserPlaylists = (id, name, description) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description })
		setUserPlaylists(userPlaylists)
	}

	useEffect(async () => {
		const { access } = await getTokens()
		setAccessState(access)
		Promise.all([USER_PLAYLIST_URL, PLAYLIST_TRACKS_URL + playlistId].map(endpoint => api.get(endpoint)))
			.then(([{ data: user }, { data: trackList }]) => {
				setUserPlaylists(user)
				if (trackList.length > 0) trackList[0].refreshUrl = PLAYLIST_TRACKS_URL + playlistId
				setTrackList(trackList)
			})
			.catch(err => console.log(err.message))
			.finally(() => setLoading(false))
	}, [route.params])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.background, flex: 1 }} {...props}>
			<PlaylistDetails
				photo={photo}
				title={title}
				tracks={trackList.length}
				tracksList={trackList}
				hasCopyPlaylistBtn={hasCopyPlaylistBtn}
				playlistId={playlistId}
			/>
			<TrackList
				photo={photo}
				title={title}
				trackList={trackList}
				isTracksRemovable={isTracksRemovable}
				setTrackList={setTrackList}
				userPlaylists={userPlaylists}
				access={accessState}
				isTrackPositionChangeable={isTrackPositionChangeable}
				navigation={navigation}
				updateUserPlaylists={updateUserPlaylists}
			/>
		</View>
	)
}
