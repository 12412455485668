import { Pressable, Text, View, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import useLayout from '../../../../Hooks/useLayout'

const LinkButton = ({ route, routeParams, title, icon, open, ...props }) => {
	const navigation = useNavigation()

	const { deviceType } = useLayout()
	return (
		<Pressable
			onPress={() => navigation.navigate(route, routeParams)}
			style={{ paddingVertical: 10, paddingHorizontal: 0 }}
			{...props}
		>
			<View style={{ flexDirection: 'row' }}>
				<Ionicons name={icon} size={deviceType === 1 ? 30 : 24} color={'#ffffff'} />
				{open && (
					<Text style={{ fontSize: Platform.OS === 'android' ? 20 : 16, color: '#ffffff', marginLeft: 10 }}>
						{title}
					</Text>
				)}
			</View>
		</Pressable>
	)
}

export default LinkButton
