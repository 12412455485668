import React, { useState } from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import useAxios from '../../../Hooks/useAxios'
import { getPreviousDay } from '../../../Helpers/timeConverter'
import { colors, PLAYLIST_BARIX, GET_USERNAME, CREATE_PLAYLIST_BARIX } from '../../../Helpers/variables'
import { PrimaryButton } from '../../../Components/Buttons/Button'
import { Select } from '../../../Components/Forms/Select'
import customAlert from '../../../Helpers/alert'

export const SetPlaylistOfflineMenu = ({ playlistId }) => {
	const api = useAxios()
	const [selectedZoneValue, setSelectedZoneValue] = useState(0)
	const [formLoading, setFormLoading] = useState(false)
	const [isOpened, setIsOpened] = useState(false)
	const zones = [
		{
			value: 0,
			label: 'Zone 1'
		},
		{
			value: 1,
			label: 'Zone 2'
		},
		{
			value: 2,
			label: 'Zone 3'
		}
	]

	const handleOfflinePlayback = async () => {
		setFormLoading(true)

		try {
			let { data: username_data, status: username_status } = await api.get(GET_USERNAME)
			if (username_status !== 200) return

			let { data } = await api.get(PLAYLIST_BARIX + playlistId)
			if (!data.length) {
				await api.post(PLAYLIST_BARIX + playlistId, {
					backend_id: playlistId,
					device_id: username_data.username
				})

				await api.put(PLAYLIST_BARIX + playlistId, {
					last_updated: getPreviousDay(),
					backend_id: playlistId,
					should_update: true
				})
			}
			const response = await api.post(CREATE_PLAYLIST_BARIX, {
				playlist_id: playlistId,
				device_id: username_data.username,
				zone: selectedZoneValue
			})
			if (response.status === 201)
				customAlert('Tilføj Spilleliste', 'Tilføjet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
		} catch (err) {
			console.log(err)
		} finally {
			setFormLoading(false)
		}
	}

	const handleSelectChange = label => {
		let newValue = zones.filter(x => x.label === label)[0].value
		setSelectedZoneValue(newValue)
	}

	const handleFormSubmit = () => {
		handleOfflinePlayback()
	}

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<Menu
				opened={isOpened}
				onBackdropPress={() => {
					setIsOpened(false)
				}}
			>
				<MenuTrigger
					onPress={() => {
						setIsOpened(true)
					}}
				>
					<View
						style={{
							backgroundColor: colors.accent,
							borderRadius: 40,
							paddingVertical: 7,
							paddingHorizontal: 20,
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						{formLoading ? (
							<ActivityIndicator />
						) : (
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>
								Use this playlist for offline playback
							</Text>
						)}
					</View>
				</MenuTrigger>
				<MenuOptions style={{ backgroundColor: '#111111', padding: 15 }}>
					<View>
						<View style={{ flexDirection: 'row', paddingTop: 15 }}>
							<Text style={{ color: 'white', marginTop: 15 }}>Vælg zone</Text>
							<Select
								handleSelectChange={handleSelectChange}
								value={selectedZoneValue}
								items={zones}
								style={{ width: 190 }}
							/>
						</View>

						<PrimaryButton onPress={() => handleFormSubmit()} title={'Save'} loading={formLoading} />
					</View>
				</MenuOptions>
			</Menu>
		</View>
	)
}
