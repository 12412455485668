import RNPickerSelect from 'react-native-picker-select'
import { View } from 'react-native'
import { useState } from 'react'
import { colors } from '../../Helpers/variables'

export const Select = ({ items, style, width = 230, handleSelectChange = undefined, ...props }) => {
	const [border, setBorder] = useState(colors.accent)
	const pickerStyle = {
		inputIOS: {
			color: colors.primaryFontColor,
			paddingTop: 13,
			paddingHorizontal: 10,
			paddingBottom: 12,
			borderColor: colors.primary,
			backgroundColor: '#353C50',
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width,
			...style
		},
		inputAndroid: {
			color: colors.primaryFontColor,
			borderColor: colors.primary,
			backgroundColor: '#353C50',
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width,
			...style
		},
		inputWeb: {
			color: 'white',
			borderColor: colors.primary,
			backgroundColor: colors.secondary,
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: 80,
			height: 30,
			fontSize: 15,
			marginLeft: 10,
			marginTop: 10,
			...style
		},
		placeholdercolor: colors.primaryFontColor,
		underline: { borderTopWidth: 0 },
		icon: {
			position: 'absolute',
			backgroundColor: 'transparent',
			borderTopWidth: 5,
			borderTopColor: '#00000099',
			borderRightWidth: 5,
			borderRightColor: 'transparent',
			borderLeftWidth: 5,
			borderLeftColor: 'transparent',
			width: 0,
			height: 0,
			top: 20,
			right: 15
		}
	}

	return (
		<View>
			{handleSelectChange ? (
				<RNPickerSelect
					placeholder={{}}
					items={items}
					{...props}
					style={pickerStyle}
					onValueChange={(value, index) => {
						handleSelectChange(items[index].label)
					}}
				/>
			) : (
				<RNPickerSelect placeholder={{}} items={items} {...props} style={pickerStyle} />
			)}
		</View>
	)
}
