import { View, Text, Pressable } from 'react-native'
import Card from './components/Card'

export default function SearchResultAlbum({ albums, navigation }) {
	return (
		<View>
			{albums && (
				<View>
					{albums.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 25, color: 'white' }}>Album</Text>
							<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								{albums.map(item => (
									<Pressable
										onPress={() =>
											navigation.navigate('AlbumTracks', { albumId: item.id, photo: item.Photo, title: item.album })
										}
										key={item.id}
									>
										<Card image={item.Photo} title={item.album} />
									</Pressable>
								))}
							</View>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
