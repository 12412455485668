import { useState } from 'react'
import { View, Text, KeyboardAvoidingView, useWindowDimensions } from 'react-native'

import { PrimaryButton } from '../../Components/Buttons/Button'
import { Input } from '../../Components/Forms/Input'
import { colors } from '../../Helpers/variables'
import useAuth from '../../Hooks/useAuth'
import AuthService from '../../Services/AuthService'

export default function LoginScreen() {
	const { height } = useWindowDimensions()
	const { setIsLoggedIn } = useAuth()

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleLogin = e => {
		e.preventDefault()
		setMessage('')
		setLoading(true)

		if (username && password) {
			AuthService.login(username, password).then(
				() => {
					setLoading(false)
					setIsLoggedIn(true)
				},
				({ response }) => {
					setLoading(false)
					setMessage(response.data.detail)
				}
			)
		} else {
			setLoading(false)
			setMessage('Please enter username and password!')
		}
	}

	return (
		<View style={{ height: height, justifyContent: 'center', alignItems: 'center', backgroundColor: colors.primary }}>
			<KeyboardAvoidingView>
				<View style={{ width: 300 }}>
					<Text style={{ fontSize: 25, fontWeight: 'bold', color: colors.accent, marginBottom: 20 }}>InstoreMusic</Text>
					<Input onChangeText={text => setUsername(text)} value={username} placeholder={'Username'} />
					<Input onChangeText={text => setPassword(text)} value={password} placeholder={'Password'} secureTextEntry />
					<PrimaryButton onPress={handleLogin} title={'Login'} loading={loading} />
					<Text style={{ color: 'red', textAlign: 'center', marginVertical: 10 }}>{message}</Text>
				</View>
			</KeyboardAvoidingView>
		</View>
	)
}
