import React, { useEffect, useState } from 'react'

import TrackList from '../TracksScreen/components/TrackList'
import { ScrollView, useWindowDimensions, View, Text, ImageBackground, Image } from 'react-native'
import { ALBUM_TRACKS_URL, colors, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import Loader from '../../Components/Loader/Loader'
import useLayout from '../../Hooks/useLayout'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'

export default function AlbumTracksScreen({ route, navigation }) {
	const [albumDetails, setAlbumDetails] = useState([])
	const { albumId } = route.params
	const [loading, setLoading] = useState(true)
	const windowHeight = useWindowDimensions().height
	const { deviceType } = useLayout()
	const api = useAxios()

	const fallback = require('../../assets/fallback_cat.png')
	const logo = require('../../assets/icon.png')

	const [userPlaylists, setUserPlaylists] = useState([])

	const updateUserPlaylists = (id, name, description) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description })
		setUserPlaylists(userPlaylists)
	}

	useEffect(async () => {
		let tracksList = []

		await api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				setUserPlaylists(data)
			})
			.catch(error => {
				console.log(error)
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})

		const { data } = await api.get(ALBUM_TRACKS_URL + albumId)
		if (data.length > 0) {
			data.map(itemMain => {
				itemMain.mm_tracks.map(item => {
					tracksList.push({
						track: item,
						Photo: itemMain.Photo,
						title: itemMain.album,
						id: item.id
					})
				})
			})
		}
		setAlbumDetails(tracksList)
		setLoading(false)
	}, [albumId])

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ maxHeight: windowHeight - 100 }}>
			{albumDetails.length > 0 && (
				<View>
					<View style={{ marginTop: 20 }}>
						<PlaylistDetails
							photo={albumDetails[0].Photo}
							title={albumDetails[0].title}
							tracks={albumDetails.length}
							tracksList={albumDetails}
						/>
					</View>
					<TrackList
						photo={albumDetails[0].Photo}
						title={albumDetails[0].title}
						trackList={albumDetails}
						key={albumDetails[0].id}
						navigation={navigation}
						userPlaylists={userPlaylists}
						updateUserPlaylists={updateUserPlaylists}
					/>
				</View>
			)}
		</ScrollView>
	)
}
