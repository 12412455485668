import { getHeaderTitle } from '@react-navigation/elements'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { colors } from '../../Helpers/variables'
import HomeScreen from '../../Screens/HomeScreen/HomeScreen'
import InternalContainerPlaylistsScreen from '../../Screens/InternalContainerPlaylistsScreen/InternalContainerPlaylistsScreen'
import InternalContainerScreen from '../../Screens/InternalContainerScreen/InternalContainerScreen'
import PlaylistScreen from '../../Screens/PlaylistScreen/PlaylistScreen'
import SearchScreen from '../../Screens/SearchScreen/SearchScreen'
import AlbumTracksScreen from '../../Screens/AlbumTracksScreen/AlbumTracksScreen'
import ArtistTracksScreen from '../../Screens/ArtistTracksScreen/ArtistTracksScreen'
import TracksScreen from '../../Screens/TracksScreen/TracksScreen'
import Header from '../Layouts/Header'
import MainLayout from '../Layouts/MainLayout'
import MyPlaylistScreen from '../../Screens/MyPlaylistScreen/MyPlaylistScreen'
import CreatePlaylistScreen from '../../Screens/CreatePlaylistScreen/CreatePlaylistScreen'
import SongRequestScreen from '../../Screens/SongRequestScreen/SongRequestScreen'
import HelpScreen from '../../Screens/HelpScreen/HelpScreen'

const Stack = createNativeStackNavigator()

export default function MainStack() {
	return (
		<MainLayout>
			<Stack.Navigator
				initialRouteName={'Home'}
				screenOptions={{
					header: ({ navigation, route, options }) => (
						<Header navigation={navigation} title={getHeaderTitle(options, route.name)} />
					),
					headerStyle: { backgroundColor: colors.primary },
					headerBackTitleVisible: false,
					headerShadowVisible: false,
					headerTintColor: colors.primary,
					contentStyle: { backgroundColor: colors.primary }
				}}
			>
				<Stack.Screen options={{ title: 'Hjem' }} name="HomeScreen" component={HomeScreen} />
				<Stack.Screen
					options={{ title: 'Internt Miljø' }}
					name="InternalContainerScreen"
					component={InternalContainerScreen}
				/>
				<Stack.Screen options={{ title: 'Spillelister' }} name="PlaylistScreen" component={PlaylistScreen} />
				<Stack.Screen options={{ title: 'Mine Spillelister' }} name="MyPlaylistsScreen" component={MyPlaylistScreen} />
				<Stack.Screen
					options={{ title: 'Internt Spillelister' }}
					name="InternalPlaylistScreen"
					component={InternalContainerPlaylistsScreen}
				/>
				<Stack.Screen options={{ title: 'Spilleliste numre' }} name="TracksScreen" component={TracksScreen} />
				<Stack.Screen options={{ title: 'Search' }} name="SearchScreen" component={SearchScreen} />
				<Stack.Screen name="ArtistTracks" options={{ title: 'Artist Tracks' }} component={ArtistTracksScreen} />
				<Stack.Screen name="AlbumTracks" options={{ title: 'Album Tracks' }} component={AlbumTracksScreen} />
				<Stack.Screen
					name="CreatePlaylistScreen"
					options={{ title: 'Opret Spillelister' }}
					component={CreatePlaylistScreen}
				/>
				<Stack.Screen name="SongRequestScreen" options={{ title: 'Anmod om en sang' }} component={SongRequestScreen} />
				<Stack.Screen name="HelpScreen" options={{ title: 'Hjælp' }} component={HelpScreen} />
			</Stack.Navigator>
		</MainLayout>
	)
}
