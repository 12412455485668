import React, { useState, useEffect } from 'react'
import { Text, View, Switch, ImageBackground, Image } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { convertTimeWithSecs } from '../../../../Helpers/timeConverter'
import { colors, MEDIAPLAYER_ACQUISITION_URL } from '../../../../Helpers/variables'
import useAxios from '../../../../Hooks/useAxios'
import useSound from '../../../../Hooks/useSound'

import TrackChangeButton from './TrackControls/TrackChangeButton'
import PlayButton from './TrackControls/PlayButton'
import RepeatButton from './TrackControls/RepeatButton'
import ShuffleButton from './TrackControls/ShuffleButton'
import VolumeControls from './TrackControls/VolumeControls'
import SeekControls from './TrackControls/SeekControls'
import truncateStr from '../../../../Helpers/truncateStr'

export default function TrackControls() {
	const fallback = require('../../../../assets/fallback_cat.png')
	const logo = require('../../../../assets/icon.png')

	const api = useAxios()
	const { handleTimerChange, mediaPlayerAcquisition, setMediaPlayerAcquisition, currentPlayingTrack } = useSound()

	const [modalVisible, setModalVisible] = useState(false)

	const getMediaPlayerAcquisition = async () => {
		const { data } = await api.get(MEDIAPLAYER_ACQUISITION_URL)
		if (data.length > 0) {
			setMediaPlayerAcquisition({
				isShuffleBtnVisible: data[0].shuffle_enabled_bool,
				isRepeatBtnVisible: data[0].repeat_enabled_bool,
				isLoaded: true
			})
		}
	}

	if (!mediaPlayerAcquisition.isLoaded) {
		getMediaPlayerAcquisition()
	}

	const [image, setImage] = useState(undefined)

	useEffect(() => {
		if (currentPlayingTrack) {
			setImage(currentPlayingTrack.album_photo)
		}
	}, [currentPlayingTrack])

	return (
		<View style={{ paddingHorizontal: 15, marginBottom: 20 }}>
			{currentPlayingTrack && (
				<View style={{ alignItems: 'center', marginVertical: 20 }}>
					<ImageBackground
						blurRadius={20}
						imageStyle={{ borderRadius: 15 }}
						source={image ? { uri: image } : fallback}
						style={{ width: 200 }}
						resizeMode={'cover'}
					>
						<Image
							source={image ? { uri: image } : logo}
							style={{
								height: 125,
								width: 125,
								borderRadius: 100,
								marginLeft: 40,
								marginTop: 25,
								borderColor: colors.accent,
								borderWidth: 2
							}}
							resizeMode={'cover'}
						/>
						<View style={{ paddingVertical: 20 }}>
							<Text style={{ color: 'white', textAlign: 'center', fontSize: 14 }}>
								{truncateStr(currentPlayingTrack.song_title, 20)}
							</Text>
							<Text style={{ color: 'white', textAlign: 'center', fontSize: 10 }}>
								{truncateStr(currentPlayingTrack.artist_name, 20)}
							</Text>
						</View>
					</ImageBackground>
				</View>
			)}
			<SeekControls />
			<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				{mediaPlayerAcquisition.isShuffleBtnVisible && <ShuffleButton />}
				<TrackChangeButton type="prev" />
				<PlayButton />
				<TrackChangeButton type="next" />
				{mediaPlayerAcquisition.isRepeatBtnVisible && <RepeatButton />}
			</View>
			<VolumeControls />
		</View>
	)
}
