import { View, Text, ImageBackground, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
	title: {
		color: 'white',
		fontSize: 20,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 'auto',
		marginBottom: 'auto',
		overflow: 'hidden'
	},
	secondTitle: {
		color: '#F4F4F4',
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 'auto',
		marginBottom: 'auto',
		overflow: 'hidden'
	}
})

export default function Card({ image, title, secondTitle = '' }) {
	const fallback = require('../../../assets/fallback_cat.png')

	return (
		<View style={styles.container}>
			<ImageBackground
				source={image ? { uri: image } : fallback}
				resizeMode="cover"
				blurRadius={10}
				imageStyle={{ borderRadius: 15 }}
				style={{ height: 170, width: 170, margin: 15 }}
			>
				<Text style={styles.title}>{title}</Text>
				{secondTitle !== '' && <Text style={styles.secondTitle}>{secondTitle}</Text>}
			</ImageBackground>
		</View>
	)
}
