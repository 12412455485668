import React, { useState } from 'react'
import { Pressable, Text, View, TouchableHighlight } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import truncateStr from '../../../Helpers/truncateStr'
import { trackStyles } from './TrackStyles'
import { colors } from '../../../Helpers/variables'
import useSound from '../../../Hooks/useSound'
import useLayout from '../../../Hooks/useLayout'
import { TrackMenu } from './TrackMenu'

const SingleTrack = ({
	track,
	tracksList,
	isTracksRemovable,
	playlistId,
	playlistTrackId,
	userPlaylists,
	setTrackList,
	navigation,
	updateUserPlaylists,
	...props
}) => {
	const { deviceType } = useLayout()
	const { handleTrackPlayer, currentPlayingTrack, playbackStatus } = useSound()
	return (
		<View style={trackStyles.trackRow} {...props}>
			<Pressable onPress={() => handleTrackPlayer(track, tracksList)} style={trackStyles.songContainer}>
				<View style={{ flexDirection: 'row' }}>
					<View style={{ marginRight: 10 }}>
						{playbackStatus.isPlaying && currentPlayingTrack?.id === track?.id ? (
							<Ionicons name="pause-circle" size={30} color={colors.accent} />
						) : (
							<Ionicons name="play-circle" size={30} color={colors.accent} />
						)}
					</View>
					<View style={{ width: 290 }}>
						<Text
							style={{
								color: playbackStatus.isPlaying && currentPlayingTrack?.id === track?.id ? colors.accent : 'white',
								fontSize: 14
							}}
						>
							{truncateStr(track.song_title, 45)}
						</Text>
						<TouchableHighlight
							onPress={() => {
								navigation.navigate('ArtistTracks', {
									artistId: track.artist,
									photo: track.album_photo,
									title: track.artist_name
								})
							}}
						>
							<View style={{ width: 'auto' }}>
								<Text style={trackStyles.albumTitle}>{truncateStr(track.artist_name, 45)}</Text>
							</View>
						</TouchableHighlight>
					</View>
				</View>
			</Pressable>
			{deviceType !== 1 && (
				<Pressable onPress={() => handleTrackPlayer(track, tracksList)} style={trackStyles.albumContainer}>
					<TouchableHighlight
						onPress={() => {
							navigation.navigate('AlbumTracks', {
								albumId: track.album,
								photo: track.album_photo,
								title: track.album_name
							})
						}}
					>
						<View style={{ width: 'auto' }}>
							<Text
								style={{
									color: playbackStatus.isPlaying && currentPlayingTrack?.id === track?.id ? colors.accent : 'white',
									fontSize: 13
								}}
							>
								{truncateStr(track.album_name, 45)}
							</Text>
						</View>
					</TouchableHighlight>
				</Pressable>
			)}
			<TrackMenu
				track={track}
				playlistId={playlistId}
				playlistTrackId={playlistTrackId}
				isTracksRemovable={isTracksRemovable}
				setTrackList={setTrackList}
				tracksList={tracksList}
				userPlaylists={userPlaylists}
				updateUserPlaylists={updateUserPlaylists}
			/>
		</View>
	)
}

function checkEqual(prevProps, nextProps) {
	return prevProps.id === nextProps.id
}

const Track = React.memo(SingleTrack, checkEqual)
export default Track
