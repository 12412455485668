import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Pressable, ImageBackground, View, Text } from 'react-native'
import useLayout from '../../../Hooks/useLayout'
import { colors } from '../../../Helpers/variables'

export default function ContainerCard({ item }) {
	const { deviceType } = useLayout()
	const { navigate } = useNavigation()

	const width = deviceType === 1 ? 230 : 190
	const [image, setImage] = React.useState(null)
	const fallback = require('../../../assets/fallback_cat.png')

	React.useEffect(() => {
		if (item.item.Photo) {
			setImage(item.item.Photo)
		}
	}, [])

	return (
		<Pressable onPress={() => navigate('InternalPlaylistScreen', { categoryId: item.item.id })}>
			<View
				style={{
					width: width,
					borderRadius: 10,
					backgroundColor: colors.secondary,
					marginBottom: 15,
					marginHorizontal: 15
				}}
			>
				<ImageBackground
					source={image ? { uri: image } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: 10 }}
					style={{
						width: width,
						height: width
					}}
				></ImageBackground>

				<View style={{ paddingHorizontal: 15, marginVertical: 20 }}>
					<Text style={{ color: 'white', textAlign: 'center' }}>{item.item.name}</Text>
				</View>
			</View>
		</Pressable>
	)
}
