import { Pressable } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import useSound from '../../../../../Hooks/useSound'
import { colors } from '../../../../../Helpers/variables'
import { useEffect, useState } from 'react'

export default function TrackChangeButton({ type }) {
	const { currentPlayingTrack, handleTrackChange } = useSound()

	const [icon, setIcon] = useState('')

	useEffect(() => {
		if (type === 'next') setIcon('play-skip-forward-sharp')
		else setIcon('play-skip-back-sharp')
	}, [])

	return (
		<Pressable disabled={currentPlayingTrack === null} onPress={() => handleTrackChange(type)}>
			<Ionicons name={icon} size={30} color={currentPlayingTrack === null ? colors.secondary : 'white'} />
		</Pressable>
	)
}
