import { ScrollView, Text, Image, Alert } from 'react-native'
import React, { useState } from 'react'
import { Input, TextArea } from '../../Components/Forms/Input'
import { PrimaryButton } from '../../Components/Buttons/Button'
import { CREATE_PLAYLIST_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import * as ImagePicker from 'expo-image-picker'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import { SecondaryButton } from '../../Components/Buttons/Button'
import customAlert from '../../Helpers/alert'

export default function CreatePlaylistScreen({
	callback = undefined,
	playlistInfo,
	processType = 'add',
	updateUserPlaylists
}) {
	const [name, setName] = useState(playlistInfo ? playlistInfo.name : '')
	const [message, setMessage] = useState({ error: false, message: '' })
	const [description, setDescription] = useState(playlistInfo ? playlistInfo.description : '')
	const [theme, setTheme] = useState(playlistInfo ? playlistInfo.theme : '')
	const [visibility, setVisibility] = useState(playlistInfo ? playlistInfo.visibility : 1)

	const [loading, setLoading] = React.useState(false)
	const [Photo, setPhoto] = useState(null)
	const { getTokens } = useTokens()

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0,
			base64: false
		})

		console.log(result)

		if (!result.cancelled) {
			let uri = result.uri

			if (uri) {
				setPhoto(Platform.OS === 'ios' ? uri.replace('file://', '') : uri)
			} else {
				const extension = uri.substring(uri.lastIndexOf('.') + 1, uri.length)
				const type = result.type + '/' + extension
				const name = 'test'
				setPhoto({ uri, type, name })
			}
			// console.log(uri)
			// console.log(type)
			// console.log(name)
			// const imageData = new ReactNativeFile({ uri, type, name })
			// try {
			// 	let blob = await fetchImageFromUri(uri)
			// 	console.log(blob)
			// 	setPhoto(blob)
			// } catch (e) {
			// 	console.log(e)
			// }
		}
	}

	const fetchImageFromUri = async uri => {
		const response = await fetch(uri)
		const blob = await response.blob()
		return blob
	}

	const handleSubmit = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Please fill in all fields' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		axios
			.post(
				CREATE_PLAYLIST_URL,
				{ name, description, theme, visibility, Photo },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					console.log('ADDED')
					customAlert('Opret spilleliste', 'Tilføjet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
					setName('')
					setDescription('')
					setPhoto(null)
					if (updateUserPlaylists) {
						updateUserPlaylists(response.data.id, name, description)
					}
				} else {
					console.log('FAILED')
					customAlert('Opret spilleliste', 'Kunne ikke tilføje', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
				setName('')
				setDescription('')
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				if (callback) callback()
			})
	}

	const handleUpdate = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Please fill in all fields' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		axios
			.put(
				USER_PLAYLIST_URL + '/' + playlistInfo.id,
				{ name, description, theme, visibility, Photo },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					console.log('UPDATED')
					customAlert('Opdater spilleliste', 'Opdater', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
					if (updateUserPlaylists) {
						updateUserPlaylists(playlistInfo.id, name, description)
					}
					setName('')
					setDescription('')
					setPhoto(null)
				} else {
					console.log('FAILED')
					customAlert('Opdater spilleliste', 'Kunne ikke opdater', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				if (callback) callback()
			})
	}

	return (
		<ScrollView>
			<Input placeholder={'Navn'} onChangeText={text => setName(text)} value={name} />
			<TextArea
				placeholder={'Beskrivelse'}
				numberOfLines={4}
				onChangeText={text => setDescription(text)}
				value={description}
			/>
			<PrimaryButton title={'Vælg et billede fra kamerarullen'} onPress={pickImage} />
			{Photo && <Image source={{ uri: Photo.uri }} style={{ width: 200, height: 200 }} />}

			<PrimaryButton
				title={processType === 'add' ? 'Opret Spillelister' : 'Opdater Spillelister'}
				loading={loading}
				onPress={() => {
					processType === 'add' ? handleSubmit() : handleUpdate()
				}}
			/>
			{callback && <SecondaryButton title={'Afbestille'} onPress={() => callback()} style={{ width: 245 }} />}
			<Text style={{ textAlign: 'center', color: message.error ? 'red' : 'green', marginVertical: 10 }}>
				{message.message}
			</Text>
		</ScrollView>
	)
}
