import React, { useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { Audio } from 'expo-av'
import convertTimeToMins from '../../Helpers/timeConverter'
import * as Sentry from 'sentry-expo'
import api from '../../Helpers/api'

export const SoundContext = React.createContext()

export const SoundProvider = ({ children }) => {
	const sound = useRef(new Audio.Sound())
	const [trackList, setTrackList] = useState([])
	const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null)

	const [playbackStatus, setPlaybackStatus] = useState({})
	const [isShuffleActive, setIsShuffleActive] = useState(false)
	const [repeatType, setRepeatType] = useState('none')
	const [repeatOnce, setRepeatOnce] = useState(false)
	const [volume, setVolume] = useState(100)

	const [trackListIndexShuffle, setTrackListIndexShuffle] = useState([])
	const [mediaPlayerAcquisition, setMediaPlayerAcquisition] = useState({
		isShuffleBtnVisible: false,
		isRepeatBtnVisible: false,
		isLoaded: false
	})

	/*
	 * function handleTrackPlayer()
	 * @description: handles the track player
	 *
	 * Checks and change tracks according to the current playing track status
	 *
	 * @param {object} track
	 */
	const handleTrackPlayer = async (track, tracksList = trackList) => {
		if (track === undefined && tracksList.length > 0) {
			track = tracksList[0]
		}

		setTrackList(tracksList)
		const result = await sound.current.getStatusAsync()
		sound.current.setOnPlaybackStatusUpdate(setPlaybackStatus)

		/*
		 * Check if there is a song playing or loaded
		 * if not, load a song and play it for the very first time
		 */
		if (!result.isLoaded) {
			setCurrentPlayingTrack(track)
			try {
				await sound.current.loadAsync({ uri: track.track_file })
				await sound.current.playAsync()
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
			return true
		}

		/*
		 * Check if there is a song playing or loaded
		 * if there is a song playing, check the current track id,
		 * if track ids matches, pause the current playing track
		 */
		if (result.isLoaded && result.isPlaying && currentPlayingTrack.id === track.id) {
			try {
				await sound.current.pauseAsync()
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
			return true
		}

		/*
		 * Same as above function but to resume the current playing track
		 */
		if (result.isLoaded && !result.isPlaying && currentPlayingTrack.id === track.id) {
			try {
				await sound.current.playAsync()
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
			return true
		}

		/*
		 * Check if there is a song playing or loaded
		 * if a song is playing or loaded,
		 * check for the passed track and current playing track ids,
		 * if the ids are different, unload the current track and
		 * start playing the newly passed in track
		 */
		if (result.isLoaded && currentPlayingTrack.id !== track.id) {
			setCurrentPlayingTrack(track)
			try {
				await sound.current.unloadAsync()
				await sound.current.loadAsync({ uri: track.track_file })
				await sound.current.playAsync()
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
			return true
		}
	}

	/*
	 * function handleSeek()
	 *
	 * @description: Handle track position change
	 * if the track is playing, update the position
	 * according to the passed in position value from the track player component
	 *
	 * @param position {number} - milliseconds
	 */
	const handleSeek = async position => {
		try {
			await sound.current.setPositionAsync(position)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleTrackPlayer()
	 *
	 * @description: Handles the track player next/previous change
	 * checks if the current track is the last track in the list
	 * if it is, play the first track in the list
	 * if not, play the next track in the list
	 *
	 * @param direction {string} - next or previous
	 */
	const handleTrackChange = async direction => {
		let index,
			currentIndex = trackList.findIndex(object => object.track.id === currentPlayingTrack.id)

		if (!isShuffleActive) {
			if (direction === 'next') {
				if (repeatType === 'one' && repeatOnce) {
					index = currentIndex + 1 >= trackList.length ? 0 : currentIndex + 1
					if (index === 0) {
						setRepeatOnce(false)
						setRepeatType('none')
					}
				} else if (repeatType === 'all') index = currentIndex + 1 >= trackList.length ? 0 : currentIndex + 1
				else {
					//repeat type is none, if end track, pause the song
					if (currentIndex + 1 >= trackList.length) {
						await sound.current.unloadAsync()
						return true
					} else index = currentIndex + 1
				}
			} else index = currentIndex === 0 ? trackList.length - 1 : currentIndex - 1
		} else {
			//shuffle is active
			let shuffleListIndex, newShuffleIndex, shuffleListNewTrackIdToPlay
			if (direction === 'next') {
				if (repeatType === 'one') index = currentIndex
				else if (repeatType === 'all') {
					shuffleListIndex = trackListIndexShuffle.findIndex(x => x.trackId === currentPlayingTrack.id)
					newShuffleIndex = shuffleListIndex + 1
					newShuffleIndex = newShuffleIndex > trackListIndexShuffle.length ? 0 : newShuffleIndex
					shuffleListNewTrackIdToPlay = trackListIndexShuffle[newShuffleIndex].trackId
					index = trackList.findIndex(x => x.track.id === shuffleListNewTrackIdToPlay)
				} else {
					//repeat type is none, if end track, pause the song
					shuffleListIndex = trackListIndexShuffle.findIndex(x => x.trackId === currentPlayingTrack.id)
					newShuffleIndex = shuffleListIndex + 1

					if (newShuffleIndex >= trackListIndexShuffle.length) {
						newShuffleIndex = 0
					}

					shuffleListNewTrackIdToPlay = trackListIndexShuffle[newShuffleIndex].trackId
					index = trackList.findIndex(x => x.track.id === shuffleListNewTrackIdToPlay)
				}
			} else {
				shuffleListIndex = trackListIndexShuffle.findIndex(x => x.trackId === currentPlayingTrack.id)
				newShuffleIndex = shuffleListIndex - 1
				newShuffleIndex = newShuffleIndex === 0 ? 0 : newShuffleIndex
				shuffleListNewTrackIdToPlay = trackListIndexShuffle[newShuffleIndex].trackId
				index =
					trackList.findIndex(x => x.track.id === shuffleListNewTrackIdToPlay) === 0
						? trackListIndexShuffle.length - 1
						: newShuffleIndex - 1
			}
		}

		try {
			await sound.current.unloadAsync()
			await handleTrackPlayer(trackList[index].track)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleClickShuffle()
	 *
	 * @description: Determines if shuffle or unshuffle of tracks list
	 */
	const handleClickShuffle = () => {
		if (trackList.length > 0) {
			if (!isShuffleActive) {
				shuffle()
			} else {
				setTrackListIndexShuffle([])
			}
			setIsShuffleActive(!isShuffleActive)
		}
	}

	/*
	 * function generateRandomIndex()
	 *
	 * @description: Generate random integer from 0 to total length of track list
	 */
	const generateRandomIndex = () => {
		let randomNum = Math.floor(Math.random() * trackList.length + 1)
		randomNum = randomNum === trackList.length ? randomNum - 1 : randomNum
		return randomNum
	}

	/*
	 * function shuffle()
	 *
	 * @description: Handles the shuffling of tracks list
	 */
	const shuffle = () => {
		let currentIndex = trackList.length,
			temporaryValue,
			randomIndex
		let trackListIndexShuffleTemp = []

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = generateRandomIndex()

			if (currentIndex === 1) {
				//Find values that are in trackList but not in trackListIndexShuffleTemp
				let result = trackList.filter(function (obj) {
					return !trackListIndexShuffleTemp.some(function (obj2) {
						return obj2.trackId == obj.track.id
					})
				})
				trackListIndexShuffleTemp.push({
					trackId: result[0].track.id
				})
				currentIndex -= 1
			} else {
				if (trackListIndexShuffleTemp.findIndex(x => x.trackId === trackList[randomIndex].track.id) === -1) {
					trackListIndexShuffleTemp.push({
						trackId: trackList[randomIndex].track.id
					})
					currentIndex -= 1
				}
			}
		}
		setTrackListIndexShuffle(trackListIndexShuffleTemp)
	}

	/*
	 * function unShuffle()
	 *
	 * @description: Handles the unshuffling of tracks list
	 */
	const unShuffle = property => {
		let sortOrder = 1
		if (property[0] === '-') {
			sortOrder = -1
			property = property.substr(1)
		}
		return function (a, b) {
			let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
			return result * sortOrder
		}
	}

	/*
	 * function handleClickRepeat()
	 *
	 * @description: Handles the behavior of repeat type(repeat all, repeat one)
	 */
	const handleClickRepeat = repeatType => {
		setRepeatType(repeatType)
		setRepeatOnce(repeatType === 'one' ? true : false)
	}

	/*
	 * function handleChangeVolume()
	 *
	 * @description: Handles the volume change
	 */
	const handleChangeVolume = async volume => {
		let newVolume = volume > 0 ? volume / 100 : volume
		try {
			await sound.current.setVolumeAsync(newVolume)
			setVolume(volume)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	// Handle track end
	useEffect(async () => {
		if (currentPlayingTrack) if (playbackStatus.didJustFinish) await handleTrackChange('next')
	}, [playbackStatus])

	useEffect(async () => {
		await Audio.setAudioModeAsync({
			allowsRecordingIOS: false,
			staysActiveInBackground: true,
			interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DUCK_OTHERS,
			playsInSilentModeIOS: true,
			shouldDuckAndroid: true,
			interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DUCK_OTHERS,
			playThroughEarpieceAndroid: false
		})
	}, [])

	return (
		<SoundContext.Provider
			value={{
				sound,
				trackList,
				setTrackList,
				currentPlayingTrack,
				setCurrentPlayingTrack,
				playbackStatus,
				handleTrackPlayer,
				handleTrackChange,
				handleSeek,
				handleClickShuffle,
				isShuffleActive,
				handleClickRepeat,
				repeatType,
				mediaPlayerAcquisition,
				setMediaPlayerAcquisition,
				volume,
				handleChangeVolume
			}}
		>
			{children}
		</SoundContext.Provider>
	)
}
