import { View, Text, Pressable } from 'react-native'
import Card from './components/Card'

export default function SearchResultArtist({ artists, navigation }) {
	return (
		<View>
			{artists && (
				<View>
					{artists.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 25, color: 'white' }}>Artist</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								{artists.map(item => (
									<Pressable
										onPress={() =>
											navigation.navigate('ArtistTracks', { artistId: item.id, photo: item.Photo, title: item.artist })
										}
										key={item.id}
									>
										<Card image={item.Photo} title={item.artist} />
									</Pressable>
								))}
							</View>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
