import { Modal, Text, Pressable, View } from 'react-native'
import { StyleSheet } from 'react-native'
import { colors } from '../../Helpers/variables'
import { SecondaryButton } from '../../Components/Buttons/Button'
import { PrimaryButton } from '../../Components/Buttons/Button'

const trackStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 200,
		alignItems: 'center',
		justifyContent: 'center',
		height: 200
	}
})

export const ConfirmationModal = ({ modalVisible, description, handleSubmit, cancelCallback }) => {
	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					cancelCallback()
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<Text style={{ marginBottom: 20, color: 'white', fontSize: 20 }}>{description}</Text>
						<View style={{ flexDirection: 'row' }}>
							<SecondaryButton title={'Anuller'} onPress={() => cancelCallback()} style={{ width: 180, margin: 10 }} />
							<PrimaryButton title={'Slet'} onPress={() => handleSubmit()} style={{ width: 180, margin: 10 }} />
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
