import React from 'react'
import { FlatList, View, Text } from 'react-native'
import { userStyles } from './UserStyles'
import { colors } from '../../Helpers/variables'
import User from './User'
import { PrimaryButton } from '../Buttons/Button'
import { UserEditModal } from '../Modals/UserEditModal'

export default function UserView({ userList, getUserList }) {
	const [isUserEditModalOpen, setUserEditModalOpen] = React.useState(false)
	const [editUserProps, setEditUserProps] = React.useState(undefined)

	const handleEditUser = item => {
		setUserEditModalOpen(true)
		setEditUserProps(item)
	}

	const handleClose = () => {
		setUserEditModalOpen(false)
		setEditUserProps(undefined)
	}

	return (
		<View style={userStyles.userList}>
			<View>
				<PrimaryButton title={'Tilføj bruger'} onPress={() => setUserEditModalOpen(true)} style={{ width: 180 }} />
			</View>
			<FlatList
				data={userList}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No users...</Text>
					</View>
				}
				renderItem={({ item }) => <User user={item} handleEditUser={handleEditUser} />}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
			/>
			{isUserEditModalOpen && (
				<UserEditModal
					modalVisible={isUserEditModalOpen}
					handleClose={handleClose}
					editUserProps={editUserProps}
					modalType={editUserProps === undefined ? 'add' : 'edit'}
					getUserList={getUserList}
				/>
			)}
		</View>
	)
}
