import React, { useEffect, useState } from 'react'

import { ScrollView, View, useWindowDimensions, FlatList, Text } from 'react-native'
import useAxios from '../../Hooks/useAxios'
import Loader from '../../Components/Loader/Loader'
import { ARTIST_TRACKS_URL, colors, USER_PLAYLIST_URL } from '../../Helpers/variables'
import PlaylistDetails from '../TracksScreen/components/PlaylistDetails'
import Track from '../TracksScreen/components/Track'

export default function ArtistTracksScreen({ route, navigation }) {
	const [artistDetails, setArtistDetails] = useState(null)
	const { artistId } = route.params
	const [loading, setLoading] = useState(true)
	const windowHeight = useWindowDimensions().height
	const api = useAxios()
	const [userPlaylists, setUserPlaylists] = useState([])

	const updateUserPlaylists = (id, name, description) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description })
		setUserPlaylists(userPlaylists)
	}

	useEffect(async () => {
		api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				setUserPlaylists(data)
			})
			.catch(error => {
				console.log(error)
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})

		const { data } = await api.get(ARTIST_TRACKS_URL + artistId)
		// console.log(JSON.stringify(data[0].mm_albums[0].mm_tracks[0].artist_name))
		setArtistDetails(data[0].mm_albums || null)
		setLoading(false)
	}, [artistId])

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ maxHeight: windowHeight - 100 }}>
			{artistDetails[0].mm_tracks && (
				<View>
					<Text style={{ fontSize: 30, color: 'white', marginBottom: 20, fontWeight: 'bold' }}>
						{artistDetails[0].mm_tracks[0].artist_name}
					</Text>
				</View>
			)}
			{artistDetails &&
				artistDetails.map((item, index) => (
					<View key={index} style={{ backgroundColor: colors.background, flex: 1 }}>
						<View style={{ marginTop: 20 }}>
							<PlaylistDetails
								photo={item.Photo}
								title={item.album}
								tracks={item.mm_tracks.length}
								tracksList={item.mm_tracks}
							/>
						</View>
						<FlatList
							data={item.mm_tracks}
							ListEmptyComponent={
								<View>
									<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No songs...</Text>
								</View>
							}
							renderItem={tracks => (
								<Track
									tracksList={item.mm_tracks}
									track={tracks.item}
									isTracksRemovable={false}
									navigation={navigation}
									userPlaylists={userPlaylists}
									updateUserPlaylists={updateUserPlaylists}
								/>
							)}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
							keyExtractor={items => items.id}
						/>
					</View>
				))}
		</ScrollView>
	)
}
