import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import useSound from '../../Hooks/useSound'
import { USER_PLAYLIST_URL } from '../../Helpers/variables'
import api from '../../Helpers/api'
import SearchResultTrackCard from './components/SearchResultTrackCard'
import { FlatGrid } from 'react-native-super-grid'

export default function SearchResultTrack({ tracks }) {
	const { handleTrackPlayer } = useSound()
	const [userPlaylists, setUserPlaylists] = useState([])

	const updateUserPlaylists = (id, name, description) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description })
		setUserPlaylists(userPlaylists)
	}

	useEffect(() => {
		api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				setUserPlaylists(data)
			})
			.catch(error => {
				console.log(error)
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}, [])

	return (
		<View>
			{tracks && (
				<View>
					{tracks.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 25, color: 'white' }}>Songs</Text>
							<FlatGrid
								itemDimension={180}
								data={tracks}
								renderItem={item => (
									<SearchResultTrackCard
										track={item.item}
										userPlaylists={userPlaylists}
										handleTrackPlayer={handleTrackPlayer}
										updateUserPlaylists={updateUserPlaylists}
									/>
								)}
								ListEmptyComponent={<Text style={{ color: 'white' }}>No tracks...</Text>}
								keyExtractor={item => item.id}
								style={{ flex: 1 }}
								contentContainerStyle={{ paddingVertical: 20 }}
								showsVerticalScrollIndicator={false}
								showsHorizontalScrollIndicator={false}
							/>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
