import React from 'react'

import { Image, ImageBackground, Pressable, Text, View, Alert } from 'react-native'
import { colors, COPYPLAYLIST_ASSIGNED_URL } from '../../../Helpers/variables'
import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import useSound from '../../../Hooks/useSound'
import useLayout from '../../../Hooks/useLayout'
import axios from 'axios'
import useTokens from '../../../Hooks/useTokens'
import Device from './Device'
import customAlert from '../../../Helpers/alert'

export default function PlaylistDetails({ tracksList, playlistId, hasCopyPlaylistBtn = false, ...props }) {
	const { photo, title, tracks } = props
	const { deviceType } = useLayout()

	const fallback = require('../../../assets/fallback_cat.png')
	const logo = require('../../../assets/icon.png')
	const { getTokens } = useTokens()

	const {
		handleTrackPlayer,
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack
	} = useSound()

	const handleCopyPlaylist = async () => {
		let is_allowed_to_be_removed = true
		let playlist = tracksList[0].playlist
		const { access } = await getTokens()

		axios
			.post(
				COPYPLAYLIST_ASSIGNED_URL,
				{ playlist, is_allowed_to_be_removed },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					customAlert('Tilføj spilleliste', 'Tilføj', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					customAlert('Tilføj spilleliste', 'Kunne ikke tilføje ', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				customAlert('Tilføj spilleliste', 'Kunne ikke tilføje ', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
				console.log(err)
			})
	}

	return (
		<View
			style={{
				flexDirection: deviceType !== 1 ? 'row' : 'column',
				alignItems: deviceType !== 1 ? 'center' : 'flex-start',
				marginBottom: 10
			}}
		>
			<ImageBackground
				blurRadius={20}
				imageStyle={{ borderRadius: 15 }}
				source={photo ? { uri: photo } : fallback}
				style={{ height: 150, width: 150 }}
				resizeMode={'cover'}
			>
				<Image
					source={photo ? { uri: photo } : logo}
					style={{
						height: 100,
						width: 100,
						borderRadius: 50,
						marginLeft: 25,
						marginTop: 25,
						borderColor: colors.accent,
						borderWidth: 2
					}}
					resizeMode={'cover'}
				/>
			</ImageBackground>
			<View style={{ marginLeft: deviceType !== 1 ? 40 : 10, marginTop: deviceType !== 1 ? 0 : 20 }}>
				<Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>{title}</Text>
				<Text style={{ color: 'white', fontSize: 16 }}>{tracks} Numre</Text>
				<View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center' }}>
					<Pressable onPress={() => handleTrackPlayer(tracksList[0].track, tracksList)}>
						<View
							style={{
								backgroundColor: colors.accent,
								borderRadius: 40,
								paddingVertical: 7,
								paddingHorizontal: 20,
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<Ionicons name="play-circle" size={18} style={{ marginRight: 5 }} color={colors.background} />
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Afspil</Text>
						</View>
					</Pressable>
					{hasCopyPlaylistBtn && tracksList.length > 0 && (
						<Pressable onPress={() => handleCopyPlaylist()}>
							<View
								style={{
									backgroundColor: colors.accent,
									borderRadius: 40,
									paddingVertical: 7,
									paddingHorizontal: 20,
									flexDirection: 'row',
									alignItems: 'center',
									marginLeft: 10
								}}
							>
								<Ionicons name="copy" size={18} style={{ marginRight: 5 }} color={colors.background} />
								<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Kopier</Text>
							</View>
						</Pressable>
					)}
					{mediaPlayerAcquisition.isShuffleBtnVisible && (
						<Pressable disabled={currentPlayingTrack === null}>
							<Ionicons
								name="shuffle"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
								style={{ marginHorizontal: 10 }}
								onPress={() => handleClickShuffle()}
							/>
						</Pressable>
					)}
					{mediaPlayerAcquisition.isRepeatBtnVisible && (
						<Pressable>
							{repeatType === 'one' ? (
								<MaterialIcons
									name="repeat-one"
									size={26}
									color={currentPlayingTrack === null ? colors.primary : colors.accent}
									style={{ marginHorizontal: 10 }}
									onPress={() => handleClickRepeat('none')}
								/>
							) : (
								<Ionicons
									name="ios-repeat-sharp"
									size={26}
									color={currentPlayingTrack === null ? colors.primary : repeatType === 'all' ? colors.accent : 'white'}
									style={{ marginHorizontal: 10 }}
									onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
								/>
							)}
						</Pressable>
					)}
					{tracksList.length > 0 && tracksList[0].playlist && <Device playlistId={playlistId} />}
				</View>
			</View>
		</View>
	)
}
