import { useWindowDimensions, Image, View, Text, Pressable } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import useLayout from '../../../Hooks/useLayout'
import LinkButton from './components/LinkButton'
import TrackControls from './components/TrackControls'
import PlayButton from './components/TrackControls/PlayButton'
import TrackChangeButton from './components/TrackControls/TrackChangeButton'
import { colors } from '../../../Helpers/variables'
export default function Sidebar() {
	const windowHeight = useWindowDimensions().height
	const { isSidebarOpen, toggleSidebar, deviceType } = useLayout()

	return (
		<View
			style={{
				height: windowHeight - 30,
				justifyContent: 'space-between',
				alignItems: 'flex-start'
			}}
		>
			<View style={{ paddingHorizontal: 25 }}>
				<View style={{ paddingHorizontal: 0, marginBottom: 20, marginTop: 10 }}>
					{isSidebarOpen ? (
						<Text style={{ fontSize: 25, fontWeight: 'bold', color: colors.accent }}>InstoreMusic</Text>
					) : (
						<Pressable onPress={toggleSidebar}>
							<Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
						</Pressable>
					)}
				</View>

				<LinkButton open={isSidebarOpen} route="HomeScreen" title={'Hjem'} icon={'ios-home'} />
				<LinkButton
					open={isSidebarOpen}
					route="InternalContainerScreen"
					title={'Internt Miljø'}
					icon={'musical-notes-sharp'}
				/>
				<LinkButton open={isSidebarOpen} route="MyPlaylistsScreen" title={'Mine spillelister'} icon={'ios-albums'} />
				<LinkButton
					open={isSidebarOpen}
					route="CreatePlaylistScreen"
					title={'Opret Spilleliste'}
					icon={'ios-library-sharp'}
				/>
				<Text style={{ fontSize: 10, color: 'white' }}>V 1.5.6</Text>
			</View>
			{isSidebarOpen ? (
				<TrackControls />
			) : (
				<View
					style={{
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: isSidebarOpen ? 'row' : 'column',
						paddingHorizontal: 10,
						paddingVertical: 10
					}}
				>
					<TrackChangeButton type={'prev'} />
					<PlayButton />
					<TrackChangeButton type={'next'} />
				</View>
			)}
		</View>
	)
}
