export function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes * 60000)
}

export default function convertTimeToMins(timerCount) {
	let endDate = new Date()
	if (timerCount > 0) {
		let timerCountMins = timerCount / 60000
		endDate = addMinutes(endDate, timerCountMins)
	}
	return endDate
}

export function secondsParser(millis) {
	if (isNaN(millis)) return '0:00'
	let minutes = Math.floor(millis / 60000)
	let seconds = ((millis % 60000) / 1000).toFixed(0)
	if (parseInt(seconds) === 60) {
		minutes = minutes + 1
		seconds = 0
	}
	return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
}

export function convertTimeWithSecs(timestamp, timerCountSeconds) {
	if (isNaN(timestamp)) timestamp = 0

	if (timerCountSeconds === undefined) timerCountSeconds = 0

	let hrs = Math.floor(timestamp / 60)
	let minutes = Math.floor(timestamp - hrs * 60)
	if (minutes < 10) {
		minutes = '0' + minutes
	}

	let seconds = timerCountSeconds
	if (seconds === 60) seconds = 0
	if (parseInt(seconds) < 10) {
		seconds = '0' + seconds
	}
	timestamp = hrs + ':' + minutes + ':' + seconds
	return timestamp
}

export function getPreviousDay(date = new Date()) {
	let d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear()

	if (month.length < 2) month = '0' + month
	if (day.length < 2) day = '0' + day

	return [year, month, day].join('-')

	return d
}

export function convertDateToTimeHHmmss(time) {
	return ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2) + ':' + '00'
}

export function formatTwoDigits(n) {
	return n < 10 ? '0' + n : n
}
