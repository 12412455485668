import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import { Provider } from 'react-native-paper'
import { colors } from '../../Helpers/variables'
import Sidebar from './Sidebar/Sidebar'

export default function MainLayout({ children }) {
	const { height } = useWindowDimensions()

	return (
		<Provider>
			<View style={{ backgroundColor: colors.primary, paddingTop: 8, overflow: 'hidden' }}>
				<View style={{ flexDirection: 'row' }}>
					<Sidebar />
					<View style={{ minHeight: height - 10, paddingRight: 10, flex: 2 }}>{children}</View>
				</View>
			</View>
		</Provider>
	)
}
