import { useState } from 'react'
import { View, Pressable } from 'react-native'
import { TrackMenu } from '../../TracksScreen/components/TrackMenu'
import Card from './Card'

export default function SearchResultTrackCard({ track, userPlaylists, handleTrackPlayer, updateUserPlaylists }) {
	return (
		<View
			key={'main' + track.id}
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				height: 165,
				marginBottom: 30
			}}
		>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					width: '100%',
					height: '100%'
				}}
			>
				<Pressable onPress={() => handleTrackPlayer(track, [])} key={track.id}>
					<Card
						image={track.album_photo}
						title={track.song_title}
						secondTitle={track.artist_name}
						hasTrackMenuBtn={true}
					/>
				</Pressable>
			</View>

			<View style={{ marginTop: -140, marginLeft: 120 }} key={'submaintrack' + track.id}>
				<TrackMenu
					key={'trackmenucaller' + track.id}
					track={track}
					isTracksRemovable={false}
					userPlaylists={userPlaylists}
					updateUserPlaylists={updateUserPlaylists}
				/>
			</View>
		</View>
	)
}
