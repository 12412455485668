import { useEffect, useState } from 'react'
import { View, Text, useWindowDimensions, Platform } from 'react-native'

import Loader from '../../Components/Loader/Loader'

import { colors } from '../../Helpers/variables'
import FeedbackScreen from '../FeedbackScreen/FeedbackScreen'
import { PrimaryButton } from '../../Components/Buttons/Button'
import FileViewer from 'react-file-viewer'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'

const Manual = () => {
	return <FileViewer fileType={'pdf'} filePath={require('../../assets/Musicmind_UserMaual.pdf')} />
}

const renderScene = SceneMap({
	first: Manual,
	second: FeedbackScreen
})

export default function HelpScreen({ navigation }) {
	const { height, width } = useWindowDimensions()

	const [loading, setLoading] = useState(true)
	const [index, setIndex] = useState(0)
	const [routes] = useState([
		{ key: 'first', title: 'Brugermanual' },
		{ key: 'second', title: 'Feedback' }
	])

	const renderTabBar = props => (
		<TabBar {...props} indicatorStyle={{ backgroundColor: 'white' }} style={{ backgroundColor: colors.secondary }} />
	)

	useEffect(() => {
		setLoading(false)
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 90 }}>
			<TabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				onIndexChange={setIndex}
				initialLayout={{ width: width }}
				renderTabBar={renderTabBar}
			/>
		</View>
	)
}
