import { useEffect, useState } from 'react'
import { View, FlatList, Text, useWindowDimensions, Platform, Alert } from 'react-native'
import * as Sentry from 'sentry-expo'
import { PrimaryButton } from '../../Components/Buttons/Button'

import Loader from '../../Components/Loader/Loader'
import HomeContainerModal from '../../Components/Modals/HomeContainerModal'
import Playlist from '../../Components/Playlists/Playlist'

import { colors, MAIN_CONTAINER_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function AdminHomeContainerScreen({ navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()
	const [containers, setContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setModalOpen] = useState(false)
	const [containerInfo, setContainerInfo] = useState({})
	const [processType, setProcessType] = useState('update')

	const handleEditContainers = item => {
		setModalOpen(true)
		setContainerInfo(item)
	}

	const getContainers = () => {
		api
			.get(MAIN_CONTAINER_URL)
			.then(({ data }) => {
				setContainers(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	const handleDeleteContainers = item => {
		api
			.delete(MAIN_CONTAINER_URL + item.id)
			.then(response => {
				if (response.status === 204) {
					getContainers()
					Alert.alert('Slet intern beholder', 'Fjernet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					Alert.alert('Slet intern beholder', 'Kunne ikke fjernes', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				Alert.alert('Slet intern beholder', 'Kunne ikke fjernes', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
				console.log(err)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getContainers()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 130 }}>
			<View>
				<PrimaryButton
					title={'Tilføj spilleliste'}
					onPress={() => {
						setModalOpen(true)
						setProcessType('add')
						setContainerInfo({})
					}}
					style={{ width: 180 }}
				/>
			</View>
			<FlatList
				data={containers}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No containers...</Text>
					</View>
				}
				renderItem={({ item }) => (
					<Playlist
						playlist={item}
						handleEditPlaylist={handleEditContainers}
						hasDeleteBtn={true}
						handleDeletePlaylist={handleDeleteContainers}
					/>
				)}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
			/>
			{isModalOpen && (
				<HomeContainerModal
					modalVisible={isModalOpen}
					setModalVisible={setModalOpen}
					containerInfo={containerInfo}
					getContainers={getContainers}
					processType={processType}
					setProcessType={setProcessType}
					containers={containers}
				/>
			)}
		</View>
	)
}
