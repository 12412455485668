import { Text, View } from 'react-native'
import truncateStr from '../../Helpers/truncateStr'
import { PrimaryButton } from '../Buttons/Button'
import { styles } from './PlaylistStyles'

const Playlist = ({
	playlist,
	handleEditPlaylist,
	handleDeletePlaylist,
	buttonTitle = 'Redigere',
	hasDeleteBtn = false,
	...props
}) => {
	return (
		<View style={styles.playlistRow} {...props}>
			<View style={styles.rowContainer}>
				<Text style={styles.albumTitle}>{truncateStr(playlist.name, 45)}</Text>
			</View>
			<View>
				<PrimaryButton
					title={buttonTitle}
					onPress={() => handleEditPlaylist(playlist)}
					style={{ width: 180, margin: 10 }}
				/>
			</View>
			{hasDeleteBtn && (
				<View>
					<PrimaryButton
						title={'Slet'}
						onPress={() => handleDeletePlaylist(playlist)}
						style={{ width: 180, margin: 10 }}
					/>
				</View>
			)}
		</View>
	)
}

export default Playlist
