import { TextInput, TouchableHighlight, View } from 'react-native'
import { Input } from './Input'
import { TimePickerModal } from 'react-native-paper-dates'
import React, { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import { formatTwoDigits } from '../../Helpers/timeConverter'

export const TimePickerInput = ({ style, onChange, value, isDisabled = false, ...props }) => {
	const [visible, setVisible] = useState(false)
	const [border, setBorder] = useState(colors.primary)
	const curDate = new Date()
	const [hrs, setHrs] = useState(curDate.getHours())
	const [mins, setMins] = useState(curDate.getMinutes())

	useEffect(() => {
		if (value !== '') {
			let tempVal = value.split(':')
			setHrs(parseInt(tempVal[0]))
			setMins(parseInt(tempVal[1]))
		}
	}, [value])

	const onDismiss = () => {
		setVisible(false)
	}

	const onConfirm = (hours, minutes) => {
		setVisible(false)
		hours = formatTwoDigits(hours)
		minutes = formatTwoDigits(minutes)
		onChange(hours + ':' + minutes + ':' + '00')
	}

	return (
		<View>
			<TimePickerModal
				visible={visible}
				onDismiss={() => onDismiss()}
				onConfirm={e => {
					onConfirm(e.hours, e.minutes)
				}}
				label="Select time" // optional, default 'Select time'
				uppercase={false} // optional, default is true
				cancelLabel="Cancel" // optional, default: 'Cancel'
				confirmLabel="Ok" // optional, default: 'Ok'
				animationType="fade" // optional, default is 'none'
				locale="en" // optional, default is automically detected by your system
				// keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
				// clockIcon="clock-outline" // optional, default is "clock-outline"
				hours={hrs}
				minutes={mins}
			/>
			<TouchableHighlight onPress={() => setVisible(true)} disabled={isDisabled}>
				<TextInput
					style={{
						borderColor: border,
						backgroundColor: isDisabled ? colors.primary : colors.secondary,
						borderWidth: 2,
						borderRadius: 5,
						paddingVertical: 10,
						paddingHorizontal: 20,
						color: isDisabled ? 'gray' : 'white',
						width: 100,
						height: 40,
						marginLeft: 15,
						...style
					}}
					underlineColorAndroid="transparent"
					onFocus={() => setBorder(colors.accent)}
					onBlur={() => setBorder(colors.primary)}
					placeholderTextColor={'#cdcdcd'}
					autoCapitalize="none"
					clearButtonMode="while-editing"
					value={value}
					{...props}
				/>
			</TouchableHighlight>
		</View>
	)
}
