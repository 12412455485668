import { useState, useEffect } from 'react'

import { View, ScrollView, useWindowDimensions, Text, TouchableHighlight } from 'react-native'
import SearchResultTrack from './SearchResultTrack'
import SearchResultArtist from './SearchResultArtist'
import SearchResultAlbum from './SearchResultAlbum'
import { SEARCH_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import Loader from '../../Components/Loader/Loader'

export default function SearchScreen({ route, navigation }) {
	const [searchResults, setSearchResults] = useState([])
	const [loading, setLoading] = useState(true)
	const windowHeight = useWindowDimensions().height

	const api = useAxios()

	useEffect(async () => {
		const { data } = await api.get(SEARCH_URL + route.params.searchInput)
		setSearchResults(data)
		setLoading(false)
	}, [route.params.searchInput])

	return loading ? (
		<Loader />
	) : (
		<View>
			{searchResults.Albums !== undefined && searchResults.Artists !== undefined && searchResults.Tracks !== undefined && (
				<View>
					{searchResults.Albums.length === 0 &&
						searchResults.Artists.length === 0 &&
						searchResults.Tracks.length === 0 && (
							<View>
								<Text style={{ color: 'white', marginLeft: 4 }}>
									Der blev ikke fundet noget, som matcher din søgning.
								</Text>
							</View>
						)}
				</View>
			)}

			<View style={{ flexDirection: 'row', marginBottom: 15 }}>
				<Text style={{ color: 'white', fontSize: 20 }}>Fandt du ikke det, du ledte efter? </Text>
				<TouchableHighlight
					onPress={() => {
						navigation.navigate('SongRequestScreen')
					}}
				>
					<Text style={{ color: 'blue', fontSize: 20 }}>Anmod om en sang her</Text>
				</TouchableHighlight>
			</View>

			<ScrollView style={{ maxHeight: windowHeight - 120 }}>
				<SearchResultTrack tracks={searchResults.Tracks} />
				<SearchResultArtist artists={searchResults.Artists} navigation={navigation} />
				<SearchResultAlbum albums={searchResults.Albums} navigation={navigation} />
			</ScrollView>
		</View>
	)
}
