import React, { useEffect, useState } from 'react'

import { ActivityIndicator, Pressable, Text, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import useLayout from '../../../Hooks/useLayout'
import useAxios from '../../../Hooks/useAxios'
import { getPreviousDay } from '../../../Helpers/timeConverter'
import {
	colors,
	PLAYLIST_BARIX,
	GET_USERNAME,
	CREATE_PLAYLIST_BARIX,
	LOOP_STREAMING,
	LOOP_BOOL,
	LOOP,
	LOOP_DEVICE
} from '../../../Helpers/variables'
import { PrimaryButton } from '../../../Components/Buttons/Button'
import { SetPlaylistOfflineMenu } from './SetPlaylistOfflineMenu'

export default function Device({ playlistId }) {
	const { deviceType } = useLayout()
	const api = useAxios()
	const [isDeviceOnline, setIsDeviceOnline] = useState(false)
	const [isStreaming, setIsStreaming] = useState(false)
	const [isLoadingStream, setIsLoadingStream] = useState(false)
	const [isLoadingDeviceStatus, setIsLoadingDeviceStatus] = useState(true)
	const [isStreamingOpen, setIsStreamingOpen] = useState(false)

	const getDeviceUsername = async () => {
		try {
			const { status, data } = await api.get(GET_USERNAME)
			if (status === 200) {
				getLoopDevice(data.username)
			}
		} catch (resp) {
			let statusCode = resp.response.status
			console.log(statusCode)
		}
	}

	const getLoopDevice = async deviceId => {
		try {
			const { status, data } = await api.get(LOOP_DEVICE + deviceId)
			if (status === 200) {
				if (data === 'Normal') setIsDeviceOnline(true)
				else setIsDeviceOnline(false)
				setIsLoadingDeviceStatus(false)
			} else setIsLoadingDeviceStatus(false)
		} catch (resp) {
			let statusCode = resp.response.status
			if (statusCode === 401) getLoopAuth(deviceId)
			else console.log(statusCode)
		}
	}

	const getLoopAuth = async deviceId => {
		try {
			const { status } = await api.get(LOOP)
			if (status === 201) getLoopDevice(deviceId)
		} catch (resp) {
			let statusCode = resp.response.status
			console.log(statusCode)
		}
	}

	const checkDeviceStatus = () => {
		getDeviceUsername()
	}

	useEffect(async () => {
		const { data } = await api.get(LOOP_BOOL)
		setIsStreaming(data[0].play_pause_bool)
		checkDeviceStatus()
	}, [])

	const handleStreamPlaylist = async () => {
		try {
			setIsLoadingStream(true)
			if (!isStreaming) await api.post(LOOP_STREAMING, { playlist: playlistId })
			await api.post(LOOP_BOOL, { play: !isStreaming })
		} catch (err) {
			console.log(err)
		} finally {
			setIsLoadingStream(false)
			setIsStreaming(prev => !prev)
		}
	}

	return (
		<View
			style={{
				flexDirection: deviceType !== 1 ? 'row' : 'column',
				alignItems: deviceType !== 1 ? 'center' : 'flex-start',
				marginTop: -50,
				borderColor: 'gray',
				borderRadius: 40
			}}
		>
			<View style={{ marginLeft: deviceType !== 1 ? 40 : 10 }}>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{isLoadingDeviceStatus ? (
						<ActivityIndicator />
					) : (
						<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
							<Ionicons
								name="wifi"
								size={22}
								style={{ marginRight: 5 }}
								color={isDeviceOnline ? colors.accent : 'red'}
							/>
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}>
								Device is {isDeviceOnline ? 'online' : 'offline'}
							</Text>
						</View>
					)}
				</View>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
					<SetPlaylistOfflineMenu playlistId={playlistId} />
					<Pressable
						onPress={() => handleStreamPlaylist()}
						style={{
							backgroundColor: colors.accent,
							borderRadius: 40,
							paddingVertical: 7,
							paddingHorizontal: 20,
							flexDirection: 'row',
							minWidth: 200,
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: 10
						}}
					>
						{/* <View style={{}}> */}
						{isLoadingStream ? (
							<ActivityIndicator />
						) : !isStreaming ? (
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Stream this playlist</Text>
						) : (
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Stop streaming</Text>
						)}
						{/* </View> */}
					</Pressable>
				</View>
			</View>
		</View>
	)
}
