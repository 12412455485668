import { useEffect, useState } from 'react'
import { View, FlatList, Text, useWindowDimensions, Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

import Loader from '../../Components/Loader/Loader'
import { AssignUserToPlaylistModal } from '../../Components/Modals/AssignUserToPlaylistModal'
import Playlist from '../../Components/Playlists/Playlist'

import { colors, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function UserToPlaylist({ navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()
	const [playlist, setPlaylist] = useState([])
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setModalOpen] = useState(false)
	const [playlistName, setPlaylistName] = useState('')
	const [playlistId, setPlaylistId] = useState(0)

	const handleEditPlaylist = item => {
		setModalOpen(true)
		setPlaylistName(item.name)
		setPlaylistId(item.id)
	}

	const getPlaylist = () => {
		api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				setPlaylist(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getPlaylist()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 130 }}>
			<FlatList
				data={playlist}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No playlist...</Text>
					</View>
				}
				renderItem={({ item }) => (
					<Playlist playlist={item} handleEditPlaylist={handleEditPlaylist} buttonTitle={'Tildel brugere'} />
				)}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
			/>
			{isModalOpen && (
				<AssignUserToPlaylistModal
					setModalOpen={setModalOpen}
					isModalOpen={isModalOpen}
					playlist={playlist}
					playlistName={playlistName}
					playlistId={playlistId}
				/>
			)}
		</View>
	)
}
