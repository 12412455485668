import { Pressable } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import useSound from '../../../../../Hooks/useSound'
import { colors } from '../../../../../Helpers/variables'

export default function PlayButton() {
	const { currentPlayingTrack, playbackStatus, handleTrackPlayer } = useSound()
	return (
		<Pressable disabled={currentPlayingTrack === null} onPress={() => handleTrackPlayer(currentPlayingTrack)}>
			{!playbackStatus.isPlaying ? (
				<Ionicons
					name="play-circle"
					size={55}
					color={currentPlayingTrack === null ? colors.secondary : colors.accent}
				/>
			) : (
				<Ionicons
					name="pause-circle"
					size={55}
					color={currentPlayingTrack === null ? colors.secondary : colors.accent}
				/>
			)}
		</Pressable>
	)
}
