import { useState } from 'react'
import { ImageBackground, View, Text, Pressable } from 'react-native'
import { colors } from '../../Helpers/variables'
import { InfoButton } from '../Buttons/Button'
import { Popup } from './Popup'

export const PlaylistCard = ({ item, handlePlaylistPress, width }) => {
	const playlist = item.item

	const [image, setImage] = useState(playlist.Photo)
	const fallback = require('../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)
	return (
		<Pressable
			onPress={() =>
				handlePlaylistPress(playlist.playlist || playlist.id, playlist.Photo, playlist.playlist_name || playlist.name)
			}
		>
			<View
				style={{
					width: width,
					borderRadius: 10,
					backgroundColor: colors.secondary,
					marginBottom: 15,
					marginHorizontal: 15
				}}
			>
				<ImageBackground
					source={image ? { uri: image } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: 10 }}
					style={{
						width: width,
						height: width
					}}
				>
					{playlist.description && <InfoButton setModalVisible={setModalVisible} />}
				</ImageBackground>

				<View style={{ paddingHorizontal: 15, marginVertical: 20 }}>
					<Text style={{ color: 'white', textAlign: 'center' }}>{playlist.playlist_name || playlist.name}</Text>
				</View>
				{modalVisible && (
					<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={playlist.description} />
				)}
			</View>
		</Pressable>
	)
}
