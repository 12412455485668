import Checkbox from 'expo-checkbox'
import React, { useState } from 'react'
import { StyleSheet, Text, View, Pressable } from 'react-native'
import { colors } from '../../Helpers/variables'

const styles = StyleSheet.create({
	container: {
		flex: 1
		// marginHorizontal: 16,
		// marginVertical: 32
	},
	section: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	paragraph: {
		fontSize: 15,
		color: 'white'
	},
	checkbox: {
		margin: 8
	}
})

export const CheckboxTicker = ({ isChecked, label, handleCheckboxClick, item = undefined }) => {
	return (
		<View style={styles.container}>
			<Pressable
				onPress={() => {
					handleCheckboxClick(item, isChecked)
				}}
			>
				<View style={styles.section}>
					<Checkbox style={styles.checkbox} value={isChecked} color={isChecked ? colors.accent : undefined} />
					<Text style={styles.paragraph}>{label}</Text>
				</View>
			</Pressable>
		</View>
	)
}
