import React, { useState } from 'react'
import { StyleSheet, Text, View, FlatList, ScrollView } from 'react-native'
import { colors } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import { CheckboxTicker } from './CheckboxTicker'

const styles = StyleSheet.create({
	main: {
		width: 195
	},
	container: {
		marginHorizontal: 16,
		color: 'white',
		backgroundColor: colors.primary,
		overflow: 'visible',
		height: 235,
		maxHeight: 235
	},
	title: {
		color: 'white',
		marginLeft: 20,
		fontSize: 16
	},
	listboxText: {
		color: 'white',
		margin: 10
	}
})

export const Listbox = ({ items, hasRemoveAll = false, title, handleRemoveAll }) => {
	return (
		<View style={styles.main}>
			<View>
				<Text style={styles.title}>{title}</Text>
			</View>
			{hasRemoveAll && (
				<View>
					<PrimaryButton title={'Fjern alt'} onPress={() => handleRemoveAll()} style={{ width: 160, marginLeft: 15 }} />
				</View>
			)}
			<ScrollView style={styles.container}>
				<FlatList
					data={items}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, margin: 10, fontSize: 20 }}>No items...</Text>
						</View>
					}
					renderItem={({ item }) => (
						<View>
							<Text style={styles.listboxText}>
								{item.username ? item.username : item.name ? item.name : item.playlist_name}
							</Text>
						</View>
					)}
					showsVerticalScrollIndicator={true}
					showsHorizontalScrollIndicator={false}
					keyExtractor={item => item.id}
				/>
			</ScrollView>
		</View>
	)
}
