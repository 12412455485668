import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import LoginScreen from '../../Screens/LoginScreen/LoginScreen'

const Stack = createNativeStackNavigator()

export default function RootStack() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen options={{ title: 'Login' }} name="LoginScreen" component={LoginScreen} />
		</Stack.Navigator>
	)
}
