import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Sentry from 'sentry-expo'

import axios from 'axios'
import { LOGIN_URL } from '../Helpers/variables'

const setTokens = async token => {
	if (Platform.OS === 'web') {
		localStorage.setItem('token', JSON.stringify(token))
	} else {
		try {
			await AsyncStorage.setItem('token', JSON.stringify(token))
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}
}

const login = (username, password) => {
	return axios
		.post(LOGIN_URL, {
			username,
			password
		})
		.then(({ data }) => {
			if (data.refresh && data.access) setTokens(data)
			return data
		})
}

const logout = async () => {
	if (Platform.OS === 'web') {
		localStorage.removeItem('token')
	} else {
		try {
			await AsyncStorage.removeItem('token')
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}
}

const isLoggedIn = async () => {
	let token
	if (Platform.OS === 'web') {
		token = JSON.parse(localStorage.getItem('token'))
	} else {
		try {
			token = JSON.parse(await AsyncStorage.getItem('token'))
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	if (token && token.access) {
		return true
	} else {
		return false
	}
}

const AuthService = {
	login,
	logout,
	isLoggedIn
}

export default AuthService
