import { View, Pressable, Text, Platform } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import * as Sentry from 'sentry-expo'

import { colors } from '../../Helpers/variables'
import { Input } from '../Forms/Input'
import useLayout from '../../Hooks/useLayout'
import AuthService from '../../Services/AuthService'
import useAuth from '../../Hooks/useAuth'
import useSound from '../../Hooks/useSound'

export default function Header({ navigation, title }) {
	const { deviceType } = useLayout()
	const { setIsLoggedIn, setIsAdminViewOpen } = useAuth()

	const { sound } = useSound()
	
	const handleSearch = async target => {
		navigation.navigate('SearchScreen', { searchInput: target.nativeEvent.text })
	}

	const handleAdminView = () => {
		setIsAdminViewOpen(false)
	}

	const handleHelp = async target => {
		navigation.navigate('HelpScreen')
	}

	const handleLogout = async () => {
		AuthService.logout()

		try {
			await sound.current.unloadAsync()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
		setIsLoggedIn(false)
		setIsAdminViewOpen(false)
	}
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				backgroundColor: colors.primary,
				paddingVertical: 10
			}}
		>
			<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
				<View style={{ flexDirection: 'row' }}>
					{navigation.canGoBack() && (
						<Pressable onPress={() => navigation.goBack()}>
							<Ionicons name="arrow-back" size={26} style={{ marginRight: 10 }} color="white" />
						</Pressable>
					)}
					<Text style={{ color: 'white', fontSize: 20 }}>{title}</Text>
				</View>
				<View style={{ flexDirection: 'row' }}>
					<Pressable onPress={handleAdminView}>
						<Ionicons name="home" style={{ marginTop: 4, marginLeft: 20 }} size={30} color="white" />
					</Pressable>
					<Pressable onPress={handleLogout}>
						<Ionicons name="power" style={{ marginTop: 4, marginLeft: 20 }} size={30} color="white" />
					</Pressable>
					<Pressable onPress={handleHelp}>
						<Ionicons name="help" style={{ marginLeft: 20 }} size={30} color="white" />
					</Pressable>
				</View>
			</View>
		</View>
	)
}
