import { useEffect, useState } from 'react'
import { View, FlatList, Text, useWindowDimensions, Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'
import UserView from '../../Components/Users/UserView'

import { colors, USERLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import PlaylistToUser from './PlaylistToUser'
import UserToPlaylist from './UserToPlaylist'

const renderScene = SceneMap({
	first: PlaylistToUser,
	second: UserToPlaylist
})

export default function AssignPlaylistUserScreen({ navigation }) {
	const api = useAxios()
	const { height, width } = useWindowDimensions()
	const [loading, setLoading] = useState(false)
	const [index, setIndex] = useState(0)
	const [routes] = useState([
		{ key: 'first', title: 'Tildel spillelister til brugerene' },
		{ key: 'second', title: 'Tildel brugeren til spillelisten' }
	])

	const renderTabBar = props => (
		<TabBar {...props} indicatorStyle={{ backgroundColor: 'white' }} style={{ backgroundColor: colors.secondary }} />
	)

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 80 }}>
			<TabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				onIndexChange={setIndex}
				initialLayout={{ width: width }}
				renderTabBar={renderTabBar}
			/>
		</View>
	)
}
