import React from 'react'
import { View } from 'react-native'

import Track from './Track'
import { trackStyles } from './TrackStyles'
import { AutoDragSortableView } from 'react-native-drag-sort'
import { PLAYLIST_TRACKS_EDIT_URL } from '../../../Helpers/variables'
import axios from 'axios'
import { Provider } from 'react-native-paper'

export default function TrackList({
	trackList,
	photo,
	title,
	isTracksRemovable,
	setTrackList,
	userPlaylists = [],
	access,
	isTrackPositionChangeable,
	navigation,
	updateUserPlaylists,
	...props
}) {
	const changePosition = async (fromIndex, toIndex) => {
		console.log('changePosition...')
		let trackItem = trackList[fromIndex]
		let position = toIndex + 1
		trackItem.position = position
		let track = trackItem.track.id
		let playlist = trackItem.playlist
		axios
			.put(
				PLAYLIST_TRACKS_EDIT_URL + trackItem.id,
				{ position, track, playlist },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					console.log('Success change position...' + trackItem.position + ' ' + trackItem.track.song_title)
				} else console.log('Failed change position...' + trackItem.position + ' ' + trackItem.track.song_title)
			})
	}

	return (
		<AutoDragSortableView
			dataSource={trackList}
			parentWidth={100}
			childrenWidth={100}
			childrenHeight={70}
			keyExtractor={(item, index) => item.id}
			onDragEnd={(fromIndex, toIndex) => {
				changePosition(fromIndex, toIndex)
			}}
			sortable={isTrackPositionChangeable ? true : false}
			renderItem={(item, index) => (
				<View style={trackStyles.trackList}>
					<Track
						track={item.track}
						tracksList={trackList}
						playlistTrackId={item.id}
						playlistId={item.playlist}
						isTracksRemovable={isTracksRemovable}
						setTrackList={setTrackList}
						userPlaylists={userPlaylists}
						navigation={navigation}
						updateUserPlaylists={updateUserPlaylists}
					/>
				</View>
			)}
		/>
	)
}
