import React from 'react'
import { View, FlatList, Text, useWindowDimensions, ScrollView } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'
import { colors, INTERNAL_CONTAINER_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import ContainerCard from './components/ContainerCard'

export default function InternalContainerScreen({ route, navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()

	const { deviceType } = useLayout()

	const [loading, setLoading] = React.useState(true)
	const [containers, setContainers] = React.useState([])

	React.useEffect(async () => {
		const { data } = await api.get(INTERNAL_CONTAINER_URL)
		setContainers(data)
		setLoading(false)
	}, [])

	return loading ? (
		<Loader />
	) : (
		<ScrollView style={{ backgroundColor: colors.primary, height: height - 80 }}>
			<Heading style={{ color: colors.accent }}>Kategorier</Heading>
			<FlatGrid
				itemDimension={200}
				data={containers}
				renderItem={item => <ContainerCard item={item} />}
				ListEmptyComponent={<Text style={{ color: 'white' }}>No data...</Text>}
				keyExtractor={item => item.id}
				// numColumns={deviceType === 1 ? 1 : 3}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingVertical: 20 }}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			/>
		</ScrollView>
	)
}
