import React from 'react'
import { useWindowDimensions, FlatList, View, Text, ScrollView } from 'react-native'
import { colors } from '../../Helpers/variables'
import useLayout from '../../Hooks/useLayout'
import { PlaylistCard } from './PlaylistCards'
import { FlatGrid } from 'react-native-super-grid'

export function PlaylistView({ playlists, handlePlaylistPress }) {
	const windowHeight = useWindowDimensions().height
	const { deviceType } = useLayout()

	return (
		<ScrollView style={{ backgroundColor: colors.background, height: windowHeight - 80 }}>
			<FlatGrid
				itemDimension={200}
				data={playlists}
				renderItem={item => (
					<PlaylistCard item={item} width={deviceType === 1 ? 230 : 190} handlePlaylistPress={handlePlaylistPress} />
				)}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
					</View>
				}
				keyExtractor={item => item.id}
				// numColumns={deviceType === 1 ? 1 : 3}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: 20 }}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			/>
		</ScrollView>
	)
}
