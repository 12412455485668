import { Modal, Text, View, Alert } from 'react-native'
import { StyleSheet } from 'react-native'
import { CHANGEPASS_URL, colors, CREATEUSER_URL, USERLIST_URL } from '../../Helpers/variables'
import { SecondaryButton } from '../Buttons/Button'
import { PrimaryButton } from '../Buttons/Button'
import { Input } from '../Forms/Input'
import { useState, useEffect } from 'react'
// import { DateTimePickerInput } from '../Forms/DateTimePicker'
import { Select } from '../Forms/Select'
import RadioButtonGroup from '../Forms/RadioButtonGroup'
import axios from 'axios'
import useTokens from '../../Hooks/useTokens'
import customAlert from '../../Helpers/alert'
import { DatePickerInput } from '../Forms/DatePickerInput'

const trackStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
		// minWidth: 200
		// alignItems: 'center',
		// justifyContent: 'center',
		// height: 400
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	}
})

export const UserEditModal = ({
	modalVisible,
	handleClose,
	modalType = 'add',
	editUserProps = undefined,
	getUserList
}) => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [expiryDate, setExpiryDate] = useState(new Date().toISOString().slice(0, 10))
	const [expiryDateTemp, setExpiryDateTemp] = useState(new Date().toISOString().slice(0, 10))
	const [userType, setUserType] = useState(1)
	const [statusValue, setStatusValue] = useState(0)
	const [stateErrorMessage, setStateErrorMessage] = useState({
		password: '',
		username: '',
		expiry: ''
	})
	const { getTokens } = useTokens()

	const userTypes = [
		{
			value: 1,
			label: 'Tidsbegrænset Login'
		},
		{
			value: 3,
			label: 'Almindelig Bruger'
		},
		{
			value: 4,
			label: 'Super Bruger'
		}
	]

	const status = [
		{
			id: '1',
			label: 'Aktiv',
			value: 'true'
		},
		{
			id: '2',
			label: 'Inaktiv',
			value: 'false'
		}
	]

	useEffect(() => {
		if (modalType === 'edit') {
			console.log(editUserProps.is_active)
			setUserType(editUserProps.user_type)
			setExpiryDateTemp(
				editUserProps.expiration_date === null || editUserProps.expiration_date === undefined
					? ''
					: editUserProps.expiration_date
			)
			setExpiryDate(
				editUserProps.expiration_date === null || editUserProps.expiration_date === undefined
					? new Date().toISOString().slice(0, 10)
					: new Date(editUserProps.expiration_date).toISOString().slice(0, 10)
			)
			setUsername(editUserProps.username)
			setStatusValue(editUserProps.is_active === true ? 0 : 1)
		}
	}, [])

	const validateUsername = errorMessage => {
		if (username === '') {
			errorMessage.username = 'Brugernavn er påkrævet'
		} else {
			errorMessage.username = ''
		}
		return errorMessage
	}

	const validateExpiry = errorMessage => {
		if (userType === 1) {
			if (expiryDateTemp === undefined || expiryDateTemp === '') {
				errorMessage.expiry = 'Udløb er påkrævet'
			}
		}
		return errorMessage
	}

	const validatePassword = errorMessage => {
		if (password !== '' && password !== undefined) {
			if (password.length < 8) errorMessage.password = 'Adgangskoden skal være mindst 8 tegn'
		} else {
			errorMessage.password = 'Adgangskode er påkrævet'
		}
		return errorMessage
	}

	const changePassword = async () => {
		console.log('changePassword')
		const { access } = await getTokens()
		axios
			.put(
				CHANGEPASS_URL + editUserProps.id + '/',
				{
					password
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					console.log('SUCCESSFULLY CHANGED PASSWORD')
				} else {
					console.log('FAILED TO CHANGE PASSWORD: ' + JSON.stringify(response))
					// customAlert('Tilføj bruger', 'Kunne ikke tilføje', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				}
			})
	}

	const createUser = async () => {
		let user_type = userType
		let is_active = true
		let expiration_date = expiryDateTemp === '' ? null : expiryDateTemp
		const { access } = await getTokens()
		axios
			.post(
				CREATEUSER_URL,
				{
					username,
					password,
					user_type,
					is_active,
					expiration_date
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					customAlert('Tilføj bruger', 'Tilføjet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
					getUserList()
				} else {
					customAlert('Tilføj bruger', 'Kunne ikke tilføje', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				}
			})
	}

	const updateUser = async () => {
		let user_type = userType
		let is_active = statusValue === 0 ? true : false
		let expiration_date = expiryDateTemp === '' ? null : expiryDateTemp
		const { access } = await getTokens()
		axios
			.put(
				USERLIST_URL + editUserProps.id + '/',
				{
					username,
					password,
					user_type,
					is_active,
					expiration_date
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					customAlert('Opdater bruger', 'Opdateret', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
					getUserList()
				} else {
					customAlert('Opdater bruger', 'Kunne ikke opdatere', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
	}

	const handleOk = () => {
		let errorMessage = {
			username: '',
			password: '',
			expiry: ''
		}
		errorMessage = validateUsername(errorMessage)
		if (modalType === 'add') {
			errorMessage = validatePassword(errorMessage)
		}
		errorMessage = validateExpiry(errorMessage)
		if (errorMessage.password === '' && errorMessage.username === '' && errorMessage.expiry === '') {
			if (modalType === 'edit') {
				updateUser()
				if (password !== '' && password !== undefined) {
					if (changePassword() === false) return
					else {
						handleClose()
					}
				} else {
					handleClose()
				}
			} else {
				createUser()
				handleClose()
			}
		} else {
			setStateErrorMessage(errorMessage)
			return
		}
	}

	const clearExpiryErroMsg = () => {
		let errorMessage = stateErrorMessage
		errorMessage.expiry = ''
		setStateErrorMessage(errorMessage)
	}

	const onChangeDateTimePicker = selectedDate => {
		setExpiryDate(selectedDate)
		// setExpiryDateTemp(selectedDate.toISOString().slice(0, 10))
		clearExpiryErroMsg()
	}

	const onChangeSelectUserType = selected => {
		let value = userTypes.filter(x => x.label === selected)[0].value
		if (value !== 1) {
			setExpiryDateTemp('')
			setExpiryDate('')
		} else {
			setExpiryDate(new Date().toISOString().slice(0, 10))
			setExpiryDateTemp(new Date().toISOString().slice(0, 10))
		}
		setUserType(value)
		clearExpiryErroMsg()
	}

	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					handleClose()
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<View style={{ flexDirection: 'row' }}>
							<Text style={[trackStyles.label]}>{modalType === 'add' ? 'Tilføj bruger' : 'Rediger bruger'}</Text>
						</View>
						<View style={{ flexDirection: 'row', marginTop: 10 }}>
							<Text style={trackStyles.label}>Brugernavn</Text>
							<Input
								style={{ width: 200, marginLeft: 45 }}
								onChangeText={text => {
									setUsername(text)
									if (text !== '') {
										let errorMessage = stateErrorMessage
										errorMessage.username = ''
										setStateErrorMessage(errorMessage)
									}
								}}
								placeholder={'Brugernavn'}
								value={username}
							/>
						</View>
						{stateErrorMessage.username !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.username}</Text>
						)}
						<View style={{ flexDirection: 'row', marginTop: 10 }}>
							<Text style={trackStyles.label}>Adgangskode</Text>
							<Input
								style={{ width: 200, marginLeft: 25 }}
								onChangeText={text => {
									setPassword(text)
									if (text !== '') {
										let errorMessage = stateErrorMessage
										errorMessage.password = ''
										setStateErrorMessage(errorMessage)
									}
								}}
								placeholder={'Adgangskode'}
								value={password}
								type="password"
							/>
						</View>
						{stateErrorMessage.password !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.password}</Text>
						)}
						<View style={{ flexDirection: 'row' }}>
							<Text style={[{ marginRight: 40 }, trackStyles.label]}>Brugertype</Text>
							<Select
								handleSelectChange={onChangeSelectUserType}
								value={userType}
								items={userTypes}
								style={{ width: 190, height: 37 }}
							/>
						</View>
						<View style={{ flexDirection: 'row' }}>
							<Text style={{ marginBottom: 20, color: 'white', fontSize: 20, marginRight: 50 }}>Udløbsdato</Text>
							{expiryDateTemp !== '' && (
								// <Input onChange={onChangeDateTimePicker} value={expiryDate} style={{ width: 190 }} />
								<DatePickerInput
									onChange={onChangeDateTimePicker}
									placeholder={''}
									value={expiryDate}
									style={{
										width: 130,
										borderColor: colors.primary,
										backgroundColor: colors.secondary,
										height: 37,
										marginTop: -10,
										marginLeft: -5
									}}
								/>
								// 			<DatePickerInput
								// 	onChange={changeBirthday}
								// 	value={birthday}
								// 	placeholder={'Birthday'}
								// 	style={{ backgroundColor: colors.secondaryInputColor }}
								// 	isBirthday={true}
								// />
							)}
							{expiryDateTemp === '' && (
								<Input
									style={{
										width: 130,
										borderColor: colors.primary,
										backgroundColor: colors.secondary,
										height: 37,
										marginLeft: -5
									}}
									placeholder={''}
									onFocus={() => {
										clearExpiryErroMsg()
										setExpiryDateTemp(new Date().toISOString().slice(0, 10))
									}}
								/>
							)}
						</View>
						{stateErrorMessage.expiry !== '' && (
							<Text style={trackStyles.errorMessage}>{stateErrorMessage.expiry}</Text>
						)}
						{modalType !== 'add' && (
							<View style={{ flexDirection: 'row' }}>
								<Text style={trackStyles.label}>Status</Text>
								<View style={{ marginLeft: 85 }}>
									<RadioButtonGroup items={status} setRadioButtons={setStatusValue} index={statusValue} />
								</View>
							</View>
						)}
						<View style={{ flexDirection: 'row', marginTop: 20 }}>
							<SecondaryButton title={'Anuller'} onPress={() => handleClose()} style={{ width: 180, margin: 10 }} />
							<PrimaryButton title={'Slet'} onPress={() => handleOk()} style={{ width: 180, margin: 10 }} />
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
