import { useEffect, useState } from 'react'
import { View, Text, Switch, ActivityIndicator, Pressable, useWindowDimensions } from 'react-native'
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { Ionicons } from '@expo/vector-icons'
import Slider from '@react-native-community/slider'
import useAxios from '../../Hooks/useAxios'
import { colors, GET_LOOP_USER_SETTING, LOOP_SETTING } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import customAlert from '../../Helpers/alert'
import { MenuDivider } from 'react-native-material-menu'

export const SettingMenu = ({ handleAdminView, isAdmin }) => {
	const api = useAxios()
	const [formState, setFormState] = useState([])
	const [loading, setLoading] = useState(false)
	const [userSettings, setUserSettings] = useState([])
	const [formLoading, setFormLoading] = useState(false)
	const [isOpened, setIsOpened] = useState(false)

	useEffect(async () => {
		try {
			setFormLoading(true)
			const { data: user } = await api.get(GET_LOOP_USER_SETTING)
			const { data: form } = await api.get(LOOP_SETTING)

			setUserSettings(user)
			if (user.length === 0) {
				const da = form.map(item => ({
					settings: item.id,
					value: item.settings_type === 1 ? false : 0,
					settings_name: item.name,
					settings_type: item.settings_type,
					title: item.title
				}))
				setFormState(da)
			} else {
				const da = user.map(item => {
					let newValue =
						item.value.toLowerCase() === 'true' ? true : item.value.toLowerCase() === 'false' ? false : item.value
					let settingInfo = form.filter(x => x.id === item.settings)[0]
					return {
						...item,
						value: newValue,
						title: settingInfo.title,
						settings_type: settingInfo.settings_type
					}
				})
				setFormState(da)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setFormLoading(false)
		}
	}, [])

	const handleValueUpdate = (item, value) => {
		const newFormState = formState.map(obj => {
			obj.id === item.id ? (obj.value = value) : obj
			return obj
		})
		setFormState(newFormState)
	}

	const addLoopSettings = itemFormState => {
		let value = itemFormState.value.toString()
		api.post(GET_LOOP_USER_SETTING, { settings: itemFormState.settings, value: value }).then(
			({ data }) => console.log(data),
			err => {
				console.log(err)
			}
		)
	}

	const patchLoopSettings = (itemFormState, setting) => {
		let value = itemFormState.value.toString()
		api.patch(GET_LOOP_USER_SETTING + setting.id, { settings: itemFormState.settings, value: value }).then(
			({ data }) => console.log(data),
			err => {
				console.log(err)
			}
		)
	}

	const getLoopSettings = () => {
		api.get(GET_LOOP_USER_SETTING).then(
			({ data }) => {
				formState.map((itemFormState, index) => {
					let setting = data.filter(x => x.settings === itemFormState.settings)[0]
					if (setting === undefined) {
						addLoopSettings(itemFormState)
					} else {
						patchLoopSettings(itemFormState, setting)
					}
					if (index === formState.length - 1) {
						setIsOpened(false)
						customAlert('Ændre indstillinger', 'Gemt', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
					}
				})
			},
			err => {
				alert(err)
			}
		)
	}

	const handleFormSubmit = () => {
		setLoading(true)
		getLoopSettings()
		setLoading(false)
	}

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<Menu
				opened={isOpened}
				onBackdropPress={() => {
					setIsOpened(false)
				}}
			>
				<MenuTrigger
					onPress={() => {
						setIsOpened(true)
					}}
				>
					<Ionicons name="settings-outline" style={{ marginLeft: 20 }} size={30} color="white" />
				</MenuTrigger>
				<MenuOptions style={{ backgroundColor: '#111111', padding: 15 }}>
					{formLoading ? (
						<ActivityIndicator color={colors.accent} />
					) : (
						<View>
							{isAdmin && (
								<View>
									<PrimaryButton onPress={handleAdminView} title={'Admin Settings'} />
									<MenuDivider color="gray" />
								</View>
							)}
							{formState.map(item => (
								<View style={{ marginVertical: 5 }} key={item.id}>
									<Text style={{ color: 'white', marginBottom: 3 }}>{item.title}</Text>
									{item.settings_type === 1 ? (
										<Switch
											thumbColor={colors.accent}
											trackColor={'gray'}
											activeThumbColor={colors.accent}
											onValueChange={() => handleValueUpdate(item, !item.value)}
											value={item.value}
										/>
									) : (
										<View>
											<Text
												style={{
													width: 100,
													textAlign: 'center',
													marginLeft:
														Math.floor(item.value) <= 20
															? Math.floor(item.value) - 40
															: Math.floor(item.value) > 20 && Math.floor(item.value) < 56
															? Math.floor(item.value) - 20
															: Math.floor(item.value) > 55 && Math.floor(item.value) < 70
															? Math.floor(item.value) - 10
															: Math.floor(item.value),
													color: 'gray'
												}}
											>
												{Math.floor(item.value)}
											</Text>
											<Slider
												style={{ marginHorizontal: 1, width: 170, height: 40 }}
												minimumValue={0}
												maximumValue={100}
												value={parseInt(item.value)}
												minimumTrackTintColor={colors.accent}
												thumbTintColor={colors.accent}
												maximumTrackTintColor={colors.secondary}
												step={1}
												thumbStyle={{ width: 15, height: 15 }}
												onSlidingComplete={value => {
													handleValueUpdate(item, value)
												}}
											/>
										</View>
									)}
								</View>
							))}
							<PrimaryButton onPress={() => handleFormSubmit()} title={'Save'} loading={loading} />
						</View>
					)}
				</MenuOptions>
			</Menu>
		</View>
	)
}
