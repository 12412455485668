import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Sentry from 'sentry-expo'
const useTokens = () => {
	const getTokens = async () => {
		if (Platform.OS === 'web') {
			return JSON.parse(localStorage.getItem('token'))
		} else {
			try {
				// if (await SecureStore.isAvailableAsync()) return JSON.parse(await SecureStore.getItemAsync('token'))
				return JSON.parse(await AsyncStorage.getItem('token'))
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
		}
	}

	const setTokens = async token => {
		if (Platform.OS === 'web') {
			localStorage.setItem('token', JSON.stringify(token))
		} else {
			try {
				await AsyncStorage.setItem('token', JSON.stringify(token))

				// if (await SecureStore.isAvailableAsync()) await SecureStore.setItemAsync('token', JSON.stringify(token))
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
		}
	}

	return { getTokens, setTokens }
}

export default useTokens
