import React from 'react'
import Loader from '../../Components/Loader/Loader'
import { PlaylistView } from '../../Components/Playlists/PlaylistView'
import { INTERNAL_PLAYLIST_GROUP_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function InternalContainerPlaylistsScreen({ route, navigation }) {
	const api = useAxios()
	const [loading, setLoading] = React.useState(true)
	const [playlists, setPlaylists] = React.useState([])

	React.useEffect(async () => {
		setLoading(true)
		const { data } = await api.get(INTERNAL_PLAYLIST_GROUP_URL + route.params.categoryId)
		setPlaylists(data)
		setLoading(false)
	}, [])

	const handlePlaylistPress = (playlistId, photo, title) => {
		navigation.navigate('TracksScreen', { playlistId, photo, title, isTracksRemovable: false })
	}

	return loading ? <Loader /> : <PlaylistView playlists={playlists} handlePlaylistPress={handlePlaylistPress} />
}
