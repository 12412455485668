import { useEffect, useState } from 'react'
import { View, FlatList, Text, useWindowDimensions, Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

import Loader from '../../Components/Loader/Loader'
import { AssignPlaylistToUserModal } from '../../Components/Modals/AssignPlaylistToUserModal'
import User from '../../Components/Users/User'

import { colors, USERLIST_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function PlaylistToUser({ navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()
	const [userList, setUserList] = useState([])
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setModalOpen] = useState(false)
	const [username, setUsername] = useState('')
	const [userid, setUserid] = useState(0)

	const handleEditUser = item => {
		setModalOpen(true)
		setUsername(item.username)
		setUserid(item.id)
	}

	const getUserList = () => {
		api
			.get(USERLIST_URL)
			.then(({ data }) => {
				setUserList(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getUserList()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 130 }}>
			<FlatList
				data={userList}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No users...</Text>
					</View>
				}
				renderItem={({ item }) => (
					<User user={item} handleEditUser={handleEditUser} buttonTitle={'Tildel spilleliste'} />
				)}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
			/>

			{isModalOpen && (
				<AssignPlaylistToUserModal
					setModalOpen={setModalOpen}
					isModalOpen={isModalOpen}
					userList={userList}
					username={username}
					userid={userid}
				/>
			)}
		</View>
	)
}
