import React, { useEffect, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import * as Device from 'expo-device'

export const LayoutContext = React.createContext()

export const LayoutProvider = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true)
	const [deviceType, setDeviceType] = useState(0)
	const [isOffline, setIsOffline] = useState(false)

	let { width } = useWindowDimensions()

	const toggleSidebar = () => {
		setIsSidebarOpen(prev => !prev)
	}

	const setOfflineOnlineStatus = (isOffline) => {
		setIsOffline(isOffline)
	}

	useEffect(() => {
		if (width <= 600) {
			setIsSidebarOpen(false)
		} else {
			setIsSidebarOpen(true)
		}
	}, [width])

	useEffect(async () => {
		if (Platform.OS !== 'web') {
			const device = await Device.getDeviceTypeAsync()
			setDeviceType(device)
			if (device === 1) {
				setIsSidebarOpen(false)
			}
		}
	}, [])

	return (
		<LayoutContext.Provider value={{ isSidebarOpen, toggleSidebar, deviceType, setOfflineOnlineStatus, isOffline }}>
			{children}
		</LayoutContext.Provider>
	)
}
