import { Modal, Text, View, Alert } from 'react-native'
import { StyleSheet } from 'react-native'
import { ASSIGNED_URL, colors, REVERSEUSERLOOKUP_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import { SecondaryButton } from '../Buttons/Button'
import { PrimaryButton } from '../Buttons/Button'
import useTokens from '../../Hooks/useTokens'
import { CheckListbox } from '../Forms/CheckListbox'
import { Listbox } from '../Forms/Listbox'
import { useState, useEffect } from 'react'
import api from '../../Helpers/api'
import axios from 'axios'
import customAlert from '../../Helpers/alert'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
		// minWidth: 200
		// alignItems: 'center',
		// justifyContent: 'center',
		// height: 400
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	}
})

export const AssignPlaylistToUserModal = ({ isModalOpen, setModalOpen, username, userid }) => {
	const { getTokens } = useTokens()
	const [selectedItems, setSelectedItems] = useState([])
	const [originalSelectectedItems, setOriginalSelectedItems] = useState([])
	const [removedItems, setRemovedItems] = useState([])
	const [playlists, setPlaylist] = useState([])
	const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
	const [isSelectAllChecked, setSelectAllChecked] = useState(false)

	const getPlaylist = () => {
		api
			.get(USER_PLAYLIST_URL)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.id - b.id
				})
				setPlaylist(data)
				setPlaylistLoaded(true)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}

	const getUserAssignedPlaylist = () => {
		api
			.get(REVERSEUSERLOOKUP_URL + userid)
			.then(({ data }) => {
				data = data.sort(function (a, b) {
					return a.playlist - b.playlist
				})
				setSelectedItems(data)
				setOriginalSelectedItems(data)

				let listboxItemsTemp = playlists
				data.map(item => {
					let itemTemp = listboxItemsTemp.filter(x => x.id === item.playlist)[0]
					listboxItemsTemp = listboxItemsTemp.filter(x => x.id !== item.playlist)
					if (itemTemp !== undefined) {
						itemTemp.isChecked = true
						listboxItemsTemp.push(itemTemp)
					}
				})
				listboxItemsTemp = listboxItemsTemp.sort(function (a, b) {
					return a.id - b.id
				})
				setPlaylist(listboxItemsTemp)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
	}

	const removeUserPlaylist = () => {
		let totalCount = 0
		removedItems.map(item => {
			api
				.delete(ASSIGNED_URL + item.id)
				.then(response => {
					if (response.status === 204) {
						if (selectedItems.length === 0) totalCount++
						console.log('remove playlist success: ' + item.id)
						if (totalCount === removedItems.length && selectedItems.length === 0) {
							customAlert('Fjernet spillelisten', 'Spillelisten er blevet fjernet til brugeren', [
								{ text: 'OK', onPress: () => console.log('OK Pressed') }
							])
							setModalOpen(false)
						}
					} else {
						console.log('remove playlist fail: ' + item.id)
					}
				})
				.catch(err => {
					console.log(err)
				})
		})
	}

	const addUserPlaylist = () => {
		console.log('add playlist to user')
		let totalCount = 0
		selectedItems.map(item => {
			handleAddUser(item)
			totalCount = totalCount + 1
			if (totalCount === selectedItems.length) {
				customAlert('Tilføj spillelisten', 'Spillelisten er blevet tildelt til brugeren', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])

				setModalOpen(false)
			}
			return true
		})
	}

	const handleAddUser = async item => {
		const { access } = await getTokens()
		let is_allowed_to_be_removed = false
		let user = userid
		let playlist = item.playlist ? item.playlist : item.id
		axios
			.post(
				ASSIGNED_URL,
				{
					is_allowed_to_be_removed,
					user,
					playlist
				},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					console.log('success: ' + item.name)
				} else {
					console.log('failed: ' + item.name)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	useEffect(() => {
		getPlaylist()
	}, [userid])

	useEffect(() => {
		getUserAssignedPlaylist()
	}, [isPlaylistLoaded])

	const refreshListboxSelectedItems = (item, isChecked) => {
		let selectedItemsTemp = selectedItems
		if (isChecked) {
			let selectedItem = selectedItemsTemp.filter(x => (x.playlist ? x.playlist : x.id) === item.id)
			if (selectedItem.length === 0) selectedItemsTemp.push(item)

			selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
				return a.id - b.id
			})
			setSelectedItems(selectedItemsTemp)
		} else {
			selectedItemsTemp = selectedItemsTemp.filter(x => (x.playlist ? x.playlist : x.id) !== item.id)
			setSelectedItems(selectedItemsTemp)

			let removedItem = originalSelectectedItems.filter(x => (x.playlist ? x.playlist : x.id) === item.id)[0]
			let removedItemsTemp = removedItems
			removedItemsTemp.push(removedItem)
			setRemovedItems(removedItemsTemp)
		}
	}

	const handleRemoveAll = () => {
		let listboxItemsTemp = playlists
		listboxItemsTemp = listboxItemsTemp.filter(x => (x.isChecked = false))
		setSelectedItems([])
		let removedItemsTemp = []
		originalSelectectedItems.map(item => {
			let removedItem = originalSelectectedItems.filter(
				x => (x.playlist ? x.playlist : x.id) === (item.playlist ? item.playlist : item.id)
			)[0]
			removedItemsTemp.push(removedItem)
		})
		setRemovedItems(removedItemsTemp)
		setSelectAllChecked(false)
	}

	const handleOk = () => {
		if (selectedItems.length > 0) addUserPlaylist()

		if (removedItems.length > 0) removeUserPlaylist()
	}

	return (
		<View style={styles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={isModalOpen}
				onRequestClose={() => {
					handleClose()
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<View style={{ flexDirection: 'row' }}>
							<Text style={[styles.label]}>{'Tildel spilleliste til ' + username}</Text>
						</View>
						<View style={{ flexDirection: 'row' }}>
							<CheckListbox
								listboxItems={playlists}
								setListboxItems={setPlaylist}
								hasSelectAll={true}
								hasListboxSelectedItems={true}
								refreshListboxSelectedItems={refreshListboxSelectedItems}
								title={'Vælg spilleliste'}
								handleRemoveAll={handleRemoveAll}
								setSelectAllChecked={setSelectAllChecked}
								isSelectAllChecked={isSelectAllChecked}
							/>
							<Listbox
								items={selectedItems}
								hasRemoveAll={true}
								title={'Udvalgte spilleliste'}
								handleRemoveAll={handleRemoveAll}
							/>
						</View>
						<View style={{ flexDirection: 'row', marginTop: 20 }}>
							<SecondaryButton
								title={'Anuller'}
								onPress={() => setModalOpen(false)}
								style={{ width: 180, margin: 10 }}
							/>
							<PrimaryButton title={'Slet'} onPress={() => handleOk()} style={{ width: 180, margin: 10 }} />
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
