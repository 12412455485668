import React from 'react'
import { SafeAreaView, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import Sidebar from './Sidebar/Sidebar'

export default function MainLayout({ children }) {
	const { height } = useWindowDimensions()

	return (
		<SafeAreaView>
			<View style={{ backgroundColor: colors.primary }}>
				<View style={{ flexDirection: 'row' }}>
					<Sidebar />
					<View style={{ minHeight: height, paddingRight: 10, flex: 2 }}>{children}</View>
				</View>
			</View>
		</SafeAreaView>
	)
}
